import React, { useCallback, useState } from "react"
import Link from "@app/components/Link"
import { HStack, VStack, Text, Box, Container, Image, AspectRatio, Button } from "@chakra-ui/react"

type Props = {
  items?: any
  title: string
}

const NavigationHeaderDesktopMega: React.FC<Props> = ({ title, items, url }) => {
  const [visible, setVisible] = useState(false)
  const handleVisibility = useCallback((visible: boolean) => setVisible(visible), [setVisible])
  return (
    <Box cursor="pointer" role="group" onMouseEnter={() => handleVisibility(true)} onMouseLeave={() => handleVisibility(false)}>
      <Text
        color="white.900"
        size="nav"
        as={props => <Link to={url} {...props} display="inline-block" pos="relative" top="-2px" />}
        textStyle="navigation1"
        key={title}
        px={4}
        pb="4"
      >
        {title}
      </Text>
      <Box
        pointerEvents={visible ? "all" : "none"}
        visibility={visible ? "visible" : "hidden"}
        opacity={visible ? 1 : 0}
        transition="opacity 0.3s ease"
        position="absolute"
        insetX="-0"
        bg="white"
        borderTop="1px solid"
        borderColor="grey.300"
        _before={{
          content: '""',
          position: "fixed",
          insetX: 0,
          top: "158px",
          bottom: 0,
          zIndex: -1,
          bg: "grey.900",
          pointerEvents: "none",
          transition: "opacity 0.3s ease",
          transitionDelay: "0.3s",
          visibility: visible ? "visible" : "hidden",
          opacity: visible ? 0.5 : 0,
        }}
      >
        <Container maxW="container.lg" px={[6, 4]} py={10}>
          <HStack alignItems="flex-start" spacing="10">
            {items?.map((navItem, index) => {
              const { type, title, url, items, item } = navItem
              return type === "sub" ? (
                <Box w="100%" key={index}>
                  {items?.map(({ title, items }, index) => (
                    <VStack key={index} width="100%" mb="12">
                      <Box width="100%">
                        <Text
                          size="10"
                          textStyle="navigation2"
                          fontWeight="800"
                          borderBottom="1px solid"
                          borderColor="grey.300"
                          pb="4"
                          mb="4"
                          w="100%"
                        >
                          {title}
                        </Text>
                        <VStack alignItems="flex-start">
                          {items?.map(({ title, url }, index) => (
                            <Text
                              size="14"
                              as={props => <Link to={url} {...props} />}
                              onClick={() => handleVisibility(false)}
                              key={index}
                              textStyle="paragraph2"
                              borderBottom="1px solid"
                              borderColor="transparent"
                              _hover={{
                                borderColor: "grey.500",
                              }}
                            >
                              {title}
                            </Text>
                          ))}
                        </VStack>
                      </Box>
                    </VStack>
                  ))}
                </Box>
              ) : type === "feature" ? (
                <Box w="100%" key={index}>
                  <Text
                    size="nav"
                    as={props => <Link to={url} {...props} />}
                    onClick={() => handleVisibility(false)}
                    key={index}
                    textStyle="navigation2"
                    fontWeight="800"
                    d="block"
                    _hover={{
                      textDecoration: "none",
                    }}
                    pb="4"
                    w="100%"
                  >
                    {title}
                  </Text>
                  <Link to={`${navItem.link}`} onClick={() => handleVisibility(false)}>
                    <AspectRatio minW="260px" ratio={0.85} mb="15">
                      <Image src={navItem.image.asset.url} boxSize="260px" objectFit="cover" />
                    </AspectRatio>
                    <Button size="sm" variant="outline" w="100%">
                      {navItem.text}
                    </Button>
                  </Link>
                </Box>
              ) : (
                <Box w="100%" key={index}>
                  <Text
                    size="nav"
                    as={props => <Link to={url} {...props} />}
                    onClick={() => handleVisibility(false)}
                    key={index}
                    textStyle="navigation2"
                    fontWeight="800"
                    d="block"
                    _hover={{
                      textDecoration: "none",
                    }}
                    pb="4"
                    w="100%"
                  >
                    {title}
                  </Text>
                  {item ? (
                    <Link to={`${url}/${item?.shopify?.handle}`} onClick={() => handleVisibility(false)}>
                      <AspectRatio minW="260px" ratio={0.85} mb="15">
                        <Image src={item.image} boxSize="260px" objectFit="cover" />
                      </AspectRatio>
                      <Button size="sm" variant="outline" w="100%">
                        Shop the {item._type}
                      </Button>
                    </Link>
                  ) : null}
                </Box>
              )
            })}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(NavigationHeaderDesktopMega)
