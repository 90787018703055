import React from "react"
import { AppProvider } from "@app/providers/app"
import { ThemeProvider } from "@app/providers/theme"
import { ConfigProvider } from "@app/providers/config"
import { SanityProvider } from "@app/providers/sanity"
import { ShopifyProvider } from "@app/providers/shopify"
import { LocationProvider } from "@app/providers/location"
import { FirebaseProvider } from "@app/providers/firebase"
import { TrackingProvider } from "@app/providers/tracking"
import { CustomerProvider } from "@app/providers/customer"
import { CheckoutProvider } from "@app/providers/checkout"
import { WishlistProvider } from "@app/providers/wishlist"

const Providers: React.FC = ({ children }) => (
  <LocationProvider>
    <ConfigProvider>
      <SanityProvider>
        <ShopifyProvider>
          <FirebaseProvider>
            <AppProvider>
              <CustomerProvider>
                <CheckoutProvider>
                  <WishlistProvider>
                    <TrackingProvider>
                      <ThemeProvider>{children}</ThemeProvider>
                    </TrackingProvider>
                  </WishlistProvider>
                </CheckoutProvider>
              </CustomerProvider>
            </AppProvider>
          </FirebaseProvider>
        </ShopifyProvider>
      </SanityProvider>
    </ConfigProvider>
  </LocationProvider>
)

export default Providers
