import React from "react"
import { useCore } from "@app/hooks/useCore"
import { HStack, Text } from "@chakra-ui/react"
import Link from "@app/components/Link"

const NavigationTerms: React.FC = () => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()

  return isBrowser ? (
    <ErrorBoundary>
      <HStack spacing={["5"]} justifyContent={["space-around", "flex-start"]} mb={[8, 0]}>
        <Text as={Link} size="8" to="/terms-and-conditions" textStyle="navigation3" _hover={{ color: "#717171" }}>
          Terms of use
        </Text>
        <Text as={Link} size="8" to="/privacy-policy" textStyle="navigation3" _hover={{ color: "#717171" }}>
          Privacy Policy
        </Text>
      </HStack>
    </ErrorBoundary>
  ) : null
}

export default React.memo(NavigationTerms)
