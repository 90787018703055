import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "./config"

export type ContextProps = {
  tracked: boolean
  setTracked: React.Dispatch<React.SetStateAction<boolean>>
  latestVersion: boolean
  colourOptionName: string
  VisibilitySensor: React.FunctionComponent
  gtm: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
}

export const TrackingContext = React.createContext<ContextProps | undefined>(undefined)

export const TrackingProvider: React.FC = ({ children }) => {
  const {
    settings: {
      product: { colourOptionName },
    },
  } = useConfigContext()
  const {
    helpers: { isDomReady },
  } = useCore()
  const [tracked, setTracked] = useState(false)

  const { tracking } = useStaticQuery<GatsbyTypes.StaticTrackingQuery>(graphql`
    query StaticTracking {
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerify
        googleTagManagerId
      }
    }
  `)

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      tracked,
      setTracked,
      latestVersion: true, //"GA4"
      colourOptionName, //"Colour"
      gtm: TagManager,
      VisibilitySensor: VisibilitySensor,
    }),
    [tracked, setTracked, colourOptionName]
  )
  useEffect(() => {
    if (tracking?.googleTagManagerId) {
      TagManager.initialize({
        gtmId: tracking?.googleTagManagerId,
      })
      setTracked(true)
    }
  }, [tracking])

  return isDomReady ? <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider> : <>{children}</>
}

export const useTrackingContext = (): ContextProps => ({ ...React.useContext(TrackingContext) } as ContextProps)
