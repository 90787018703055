module.exports = {
  app: {
    title: "Calibre Menswear",
    description:
      "Calibre is Australia’s most respected, leading mens fashion brand and considered as a pioneer in Australia’s fashion landscape.",
    url: "https://www.calibre.com.au",
    logo: "static/images/logo.svg",
    themeColor: "#ffffff",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
  },
  stores: {
    ...("development" === process.env.GATSBY_ACTIVE_ENV && {
      "calibre-menswear-stage": {
        siteLocation: "AU",
        siteCountries: "AU",
        shopName: "calibre-menswear-stage",
        shopDomain: "calibre-menswear-stage.myshopify.com",
        shopAccessToken: "5327799440f847fda3519babd691767c",
        sanityDataset: process.env.GATSBY_SANITY_DEFAULT_DATASET || "staging",
        searchIndex: "calibre-search-stage-v2",
        loyaltyEndpoint: "https://connector-v3-staging.calibre-menswear.io/api/customer-loyalty-sync",
        shopifyPlus: true,
      },
    }),
    "calibre-menswear": {
      siteLocation: "AU",
      siteCountries: "AU",
      shopName: "calibre-menswear",
      shopDomain: "checkout.calibre.com.au",
      shopAccessToken: "0f653e6f067fe06a506f9f90577c63e0",
      sanityDataset: "production",
      searchIndex: "calibre-search-v2",
      loyaltyEndpoint: "https://connector-v3.calibre-menswear.io/api/customer-loyalty-sync",
      shopifyPlus: true,
    },
  },
  services: {
    shopify: {
      apiVersion: "2021-10",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    sanity: {
      projectId: "uyenuu13",
      token:
        "skrznW1TdJMtOodbFeBuBcOUp9TpftBMNJv2OwBU72qfruyCmGtWd4wIisznJovqfMt65YNMIF8LJ5zHxxwhPFC7KAG8P9PGT5ACaQsMN7dbnogKcil7cJAsDsNivrVsPigMxL4kuboi3qKKAh2BwVgv9OSLIB68AP8GdDYwiTX7piJakm8m",
    },
    reactify: {
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        index: "calibre-search-stage-v2",
        endpoint: "https://api.search.reactify.app",
      }),
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        index: "calibre-search-v2",
        endpoint: "https://api.search.reactify.app",
      }),
      searchConfig: `https://config.search.reactify.app?shop=${process.env.GATSBY_SHOPIFY_DEFAULT_SHOP}.myshopify.com`,
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    googleMaps: {
      apiKey: "AIzaSyCsNci4eiVqyvohhqVVn_PU61GkqdfkTHo",
    },
    firebase: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        region: "australia-southeast1",
        databaseURL: "https://calibremenswear-website.firebaseio.com",
        apiKey: "AIzaSyCsNci4eiVqyvohhqVVn_PU61GkqdfkTHo",
        authDomain: "calibremenswear-website.firebaseapp.com",
        projectId: "calibremenswear-website",
        storageBucket: "calibremenswear-website.appspot.com",
        messagingSenderId: "4346721176",
        appId: "1:4346721176:web:be673d9951766a4bb571ed",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        region: "australia-southeast1",
        databaseURL: "https://calibremenswear-website-stage.firebaseio.com",
        apiKey: "AIzaSyCMKGllyW-zPAN2wfGkyLfS_kgpxJVekUQ",
        authDomain: "calibremenswear-website-stage.firebaseapp.com",
        projectId: "calibremenswear-website-stage",
        storageBucket: "calibremenswear-website-stage.appspot.com",
        messagingSenderId: "457501032301",
        appId: "1:457501032301:web:2421d5c9cb7ef4b7b8a464",
      }),
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: { shopify: { published: { eq: true }, deleted: { ne: true } } }) { edges { node { tags, shopify { handle, deleted } } } }`,
      template: "templates/product.tsx",
      parentTagPrefix: "parent",
      siblingTagPrefix: "colours",
      hidden: "hidden",
      devPageLimit: 2000,
    },
    collections: {
      query: `allSanityCollection(filter: { shopify: { published: { eq: true }, deleted: { ne: true } } }) { edges { node { shopify { handle, deleted } parent: parentCollection { shopify { handle, deleted } parent: parentCollection { shopify { handle, deleted } parent: parentCollection { shopify { handle, deleted } }} } } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 100,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 100,
    },
    categories: {
      query: `allSanityArticleCategory { edges { node { _id handle { current } } } }`,
      template: "templates/category.tsx",
      devPageLimit: 100,
    },
    stores: {
      query: `allSanityStore { edges { node { _id handle { current } } } }`,
      template: "templates/store.tsx",
      devPageLimit: 100,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    product: {
      sizeOptionName: "Size",
      colourOptionName: "Colour",
    },
    customer: {
      tagPrefix: "preferences",
    },
    keys: {
      shopify: "calibre:shopify",
      country: "calibre:country",
      location: "calibre:location",
      checkout: "calibre:checkout",
      discounts: "calibre:discounts",
      customer: "calibre:customer",
      wishlist: "calibre:wishlist",
      newsletter: "calibre:subscribe",
      announcement: "calibre:announcement",
      maintenance: "calibre:maintenance",
      password: "calibre:password",
      position: "calibre:position",
      search: "calibre:search",
      store: "calibre:store",
      collection: "calibre:collection",
    },
    params: {
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      preview: "preview",
      variant: "variant",
      wishlist: "id",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      ERROR: "/404",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      GIFTCARD: "/giftcard",
      RETURNS: "/returns-portal",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      INVALID: "/account/invalid_token",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      DETAILS: "/account/details",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/account/wishlist",
      PRODUCT: "/products",
      COLLECTION: "",
      ARTICLE: "/journal",
      CATEGORY: "/journal/category",
      STORE: "/stores",
      SIZEGUIDE: "/size-guide",
    },
    defaultRedirects: [],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/wishlist",
      "/search",
      "/cart",
    ],
  },
}
