import React from "react"
import { IconButton } from "@chakra-ui/react"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import Link from "@app/components/Link"
import { AccountIcon } from "@app/theme/icons"

const AccountWidget: React.FC = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()

  return isBrowser ? (
    <IconButton
      as={props => <Link to={customer ? routes.DASHBOARD : routes.LOGIN} {...props} />}
      variant="menu"
      aria-label="Manage account"
      icon={<AccountIcon width={["18px", "26px"]} height={["18px", "26px"]} />}
    />
  ) : null
}

export default React.memo(AccountWidget)
