export const Link = {
  baseStyle: {
    outline: "none",
    textDecoration: "none",
    _activeLink: {
      textDecoration: "none",
    },
    _hover: {
      textDecoration: "none",
    },
  },
  variants: {
    inline: {
      textDecoration: "underline",
      _hover: {
        textDecoration: "none",
        color: "grey.700",
      },
    },
  },
}
