import React from "react"
import { GridItem, Box, Flex, Image, Text, LinkBox, LinkOverlay, AspectRatio } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import ProductPrice from "@app/components/Product/ProductPrice"
import { getResponsiveProductImages } from "@app/utils/getProductImages"
import UpsellAddToCart from "@app/components/Product/UpsellAddToCart"

type Props = {
  item: any
  handleTrackingClick: () => void
}

const CartUpsellProductCards: React.FC<Props> = ({ item, handleTrackingClick }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { desktopImages } = getResponsiveProductImages(item?.images)

  const availableVariants = item.variants?.filter(variant => variant.availableForSale === true)
  if (!availableVariants) {
    return
  }

  return (
    <GridItem key={item?.title?.toString()} colSpan={1} width="100%">
      <LinkBox display="flex" flexDirection="row">
        <AspectRatio ratio={1} w="100%" objectFit="cover" maxW="142px" maxH="142px">
          <LinkOverlay
            as={Link}
            to={item?.link?.url || urlResolver(item, routes.PRODUCT)?.url}
            title={item?.title}
            onClick={handleTrackingClick}
          >
            <Image src={desktopImages[0]?.src} alt={item?.title} fallbackSrc="https://plchldr.co/i/300x300?&amp;bg=EFEFEF&amp;fc=EFEFEF" />
          </LinkOverlay>
        </AspectRatio>
        <Box pl="16px" textAlign="left">
          <LinkOverlay
            as={Link}
            to={item?.link?.url || urlResolver(item, routes.PRODUCT)?.url}
            title={item?.title}
            onClick={handleTrackingClick}
          >
            <Text size="14" fontWeight="600" mb="8px">
              {item?.title}
            </Text>
          </LinkOverlay>
          <Flex justify="left">{availableVariants[0] && <ProductPrice variant={availableVariants[0]} small={true} />}</Flex>
          <UpsellAddToCart
            product={item}
            variant={availableVariants[0]}
            loading={false}
            multipleVariants={item?.variants?.length > 1}
            productLink={item?.link?.url || urlResolver(item, routes.PRODUCT)?.url}
          />
        </Box>
      </LinkBox>
    </GridItem>
  )
}

export default React.memo(CartUpsellProductCards)
