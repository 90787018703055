export const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      outline: "none",
      px: 0,
      fontSize: "14px",
    },
  },
  sizes: {},
  variants: {
    outline: {
      field: {
        borderColor: "grey.500",
        boxShadow: "none",
        bg: "transparent",
        color: "grey.700",
        rounded: 0,
        _hover: {
          borderColor: "grey.500",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "grey.700",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.500",
          boxShadow: "none",
          bg: "grey.mid",
          color: "grey.500",
          opacity: 1,
        },
        _invalid: {
          borderColor: "red.500",
          boxShadow: "none",
          color: "red.500",
        },
      },
    },
    flushed: {
      field: {
        borderColor: "grey.500",
        marginBottom: 2,
        _hover: {
          borderColor: "grey.500",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "grey.700",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.500",
          boxShadow: "none",
          bg: "grey.mid",
          color: "grey.500",
          opacity: 1,
        },
        _invalid: {
          borderColor: "red.500",
          boxShadow: "none",
          color: "red.500",
        },
      },
    },
  },
  defaultProps: {
    variant: "flushed",
  },
}
