export const Button = {
  baseStyle: {
    fontWeight: "700",
    color: "white",
    borderRadius: 0,
    textTransform: "uppercase",
    border: "2px solid",
    letterSpacing: "0.2em",
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      pointerEvents: "none",
    },
  },
  variants: {
    solid: {
      color: "white",
      bg: "brand.primary",
      borderColor: "brand.primary",
      _hover: {
        bg: "grey.700",
        borderColor: "grey.700",
      },
      _active: {
        bg: "grey.700",
        borderColor: "grey.700",
      },
    },
    inverted: {
      bg: "transparent",
      color: "white",
      borderColor: "white",
      _hover: {
        bg: "white",
        color: "brand.primary",
      },
      _active: {
        bg: "white",
        color: "brand.primary",
      },
    },
    outline: {
      bg: "transparent",
      color: "brand.primary",
      borderColor: "brand.primary",
      _hover: {
        textDecoration: "none",
        bg: "brand.primary",
        color: "white",
      },
      _active: {
        bg: "brand.primary",
        color: "white",
      },
      _disabled: {
        color: "grey.700",
        _hover: {
          color: "grey.500",
        },
      },
    },
    menu: {
      color: "inherit",
      border: 0,
      outline: "none",
      width: ["18px", "auto"],
      height: ["18px", "auto"],
      minWidth: 0,
      _focus: {
        outline: "none",
        boxShadow: "none",
      },
      _active: {
        outline: "none",
      },
    },
    subscribe: {
      border: 0,
      color: "grey.900",
      fontSize: "11px",
      px: 0,
    },
    ghost: {
      color: "black",
      bg: "transparent",
      border: "none",
      _hover: {
        color: "grey.700",
        bg: "transparent",
      },
      _active: {
        color: "black",
        bg: "transparent",
      },
      _disabled: {
        color: "grey.500",
        bg: "transparent",
      },
    },
    link: {
      color: "black",
      bg: "transparent",
      textDecoration: "underline",
      border: "none",
      _hover: {
        color: "grey.700",
        bg: "transparent",
      },
      _disabled: {
        color: "grey.500",
        bg: "transparent",
      },
    },
  },
  sizes: {
    sm: {
      height: "32px",
      px: "18px",
      fontSize: "11px",
      borderWidth: "1px",
    },
    md: {
      height: "48px",
      px: "18px",
      fontSize: "14px",
      borderWidth: "2px",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
}
