import React from "react"
import { Flex, Box, HStack } from "@chakra-ui/react"
import Brand from "@app/components/Brand"
import CartWidget from "@app/components/Cart/CartWidget"
import WishlistWidget from "@app/components/Wishlist/WishlistWidget"
import AccountWidget from "@app/components/Account/Widget/AccountWidget"
import NavigationHeader from "@app/components/Navigation/Header/NavigationHeader"
import NavigationHeaderWidget from "@app/components/Navigation/Header/NavigationHeaderWidget"
// import Currency from "../Currency"
import { useHeader } from "./useHeader"
import Announcement from "../Announcement"
import SearchWidget from "../Search/SearchWidget"
import { SearchFormOverlay } from "../Search/Form/SearchFormOverlay"
import { useAppContext } from "@app/providers/app"

const Header: React.FC<Props> = ({ location }: any) => {
  const headerProps = useHeader(location)
  const { headerOffset, headerRef } = useAppContext()

  return (
    <>
      <Box
        position={["sticky", headerProps.isHomepage ? "fixed" : "sticky"]}
        top="0"
        insetX="0"
        bg={["white", headerProps.transparent ? "transparent" : "white"]}
        color={["grey.900", headerProps.transparent ? "white" : "grey.900"]}
        zIndex="50"
        ref={headerRef}
        _hover={{
          bg: "white",
          color: "grey.900",
          transition: "all 0.3s ease",
        }}
        borderBottom={headerProps.isHomepage ? 0 : "1px solid"}
        borderColor={"grey.200"}
        sx={{
          ".chakra-portal > .chakra-modal__content-container": {
            top: `${headerOffset}px !important`,
          },
          ".chakra-portal > div > .chakra-modal__content-container": {
            top: `${headerOffset - (headerRef?.current?.clientHeight || 0)}px !important`,
          },
        }}
      >
        <Announcement />
        <Box as="header" px={[3, 10]} pt={[3, 8]} pb={[3, 0]}>
          <Flex mb={[0, "6"]} align={["center", "flex-start"]}>
            <Box d="flex" w={[1 / 4, 1 / 3]}>
              <Box d={["none", "flex"]}>{/* <Currency /> */}</Box>
              <NavigationHeaderWidget />
            </Box>
            <Box d="flex" justifyContent="center" w={["50%", 1 / 3]} pt={[0, 3]}>
              <Brand />
            </Box>
            <HStack spacing={[3, 4]} justifyContent="flex-end" alignItems={["center", "start"]} w={[1 / 4, 1 / 3]}>
              <SearchWidget />
              <AccountWidget />
              <WishlistWidget />
              <CartWidget />
            </HStack>
          </Flex>
          <Flex d="flex" justify="center" align="start">
            <NavigationHeader />
          </Flex>
        </Box>
      </Box>
      <SearchFormOverlay {...headerProps} />
    </>
  )
}

export default Header

type Props = {
  data: any
  breadcrumbs?: Array<any>
  location: any
}
