import React, { useEffect } from "react"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useShopifyProduct } from "@app/hooks/useShopify"
import { useMaintenance } from "@app/hooks/useMaintenance"

export const withLayout =
  (Component: React.FC<{ data: any }>) =>
  ({ name = "Layout", location, children, data, hideBreadcrumbs }: any) => {
    const { dispatch } = useAppContext()
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { trackPageView, trackStoreView } = useAnalytics()

    useEffect(() => {
      selectProduct(data?.product, location?.pathname)

      trackPageView()
      if (data?.store) {
        trackStoreView(data?.store)
      }

      // Intentionally only run on path change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, data?.product])

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={data} hideBreadcrumbs={hideBreadcrumbs} location={location}>
          {children}
        </Component>
      )
    )
  }
