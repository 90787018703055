import { useStaticQuery, graphql } from "gatsby"

export const useCartSettings = () => {
  const { cartSettings } = useStaticQuery<GatsbyTypes.CARTQuery>(graphql`
    query CART {
      cartSettings: sanitySettingCart {
        id
        enableUpsells
      }
    }
  `)

  const enableUpsells = cartSettings?.enableUpsells || false

  return {
    enableUpsells,
  }
}
