import React from "react"
import { HStack, Text } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useShopifyPrice } from "@app/hooks/useShopify"

type Props = {
  variant?: any
  loading?: boolean
  small?: boolean
  justify?: boolean
}

const ProductPrice: React.FC<Props> = ({ variant, loading = false, small = false, justify = "left" }) => {
  const { activeVariant } = useAppContext()
  const { formattedPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(variant || activeVariant)

  return !loading ? (
    <HStack justify={justify} spacing={1}>
      {onSale ? (
        <>
          <Text fontSize={small ? "12px" : "14px"} lineHeight={"22px"} letterSpacing={"0.1em"} as="s" color="grey.700">
            {formattedCompareAtPrice}
          </Text>
          &nbsp;
        </>
      ) : null}
      <Text fontSize={small ? "14px" : "16px"} lineHeight={"22px"} letterSpacing={"0.1em"} fontWeight="500">
        {formattedPrice}
      </Text>
    </HStack>
  ) : (
    <div />
  )
}

export default React.memo(ProductPrice)
