import React from "react"
import { Helmet } from "react-helmet"
import { useMeta } from "@app/hooks/useMeta"
import { useConfigContext } from "@app/providers/config"
import { usePromotions } from "@app/hooks/usePromotions"

type Props = {
  data: any
  location: any
  breadcrumbs?: Array<any>
}

const Meta: React.FC<Props> = ({ data, location, breadcrumbs = [] }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const {
    settings: { routes },
  } = useConfigContext()
  const promotion = usePromotions()

  const page =
    data?.page !== null
      ? data?.page
      : data?.pages?.edges?.length
      ? data?.pages?.edges?.filter(({ node }: { node: any }) => node?.handle?.current === promotion?.homepage)?.[0]?.node
      : null

  const url = location?.pathname
  const languages = getLanguages(url)
  const metadata = getData({
    url,
    ...(data && data?.page !== undefined ? { data: { ...data, page } } : { data }),
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language,
  })
  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const tracking = getTracking()

  const search = typeof window !== "undefined" ? window.location.search : ""

  const canonical =
    data?.collection?.title?.length > 0 && search.includes("page") && !search.match(/page=1(&|$)/)
      ? `${metadata.canonical}${search}`
      : metadata.canonical

  const withMetaNoIndex = page?.metadata?.noIndex
  return (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.lang }} title={metadata?.title} meta={metatags}></Helmet>
      <Helmet htmlAttributes={{ lang: metadata?.lang }} title={metadata?.title} meta={metatags}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={canonical} rel="canonical" />
        {/* Merchant Center site verification */}
        <meta name="google-site-verification" content="DjXISO8okA4_QZqRBTkL_KycCc3WweSM2ietFHK3Byc" />
        {languages?.map(({ key, href, hrefLang, rel }, index) => (
          <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
        ))}
        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}
        {withMetaNoIndex && <meta content="noindex" name="robots" />}
        <script type="text/javascript" async src="https://cdn.reamaze.com/assets/reamaze.js"></script>
      </Helmet>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          var _support = _support || { 'ui': {}, 'user': {} };
          _support['account'] = 'calibre';
          _support['ui']['contactMode'] = 'default';
          _support['ui']['enableKb'] = 'false';
          _support['ui']['styles'] = {
            widgetColor: 'rgb(0, 0, 0)',
          };
          _support['ui']['shoutboxFacesMode'] = "brand-avatar";
          _support['ui']['shoutboxHeaderLogo'] = true;
          _support['ui']['widget'] = {
            img: 'https://cdn.shopify.com/s/files/1/2991/4042/files/0095_Homepage_Chat_Icon_cf6e60ea-fe38-4137-84e3-08d75b2cf398.png?v=1651214062',
            displayOn: 'all',
            allowBotProcessing: false,
            label: {
              text: 'Need assistance with sizing, styling or product advice? Contact us',
              mode: "notification",
              delay: 7,
              duration: 10,
              sound: false,
            },
            position: 'bottom-right',
            mobilePosition: 'bottom-right'
          };
          _support['apps'] = {
            recentConversations: {},
            faq: {"enabled":false},
            orders: {"enabled":false}
          };
          `,
        }}
      />
      {tracking.map(script => script)}
    </>
  )
}

export default React.memo(Meta)
