import { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useAppContext } from "@app/providers/app"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"

const INITIAL_STATE = { email: "", firstName: "" }

export const useNewsletter = (location: any) => {
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const { state, dispatch } = useAppContext()
  const {
    settings: { keys, routes },
  } = useConfigContext()
  const {
    helpers: { storage },
  } = useCore()
  const { callFunction, errors, loading } = useFunctions()

  const { newsletter } = useStaticQuery<GatsbyTypes.StaticNewsletterQuery>(graphql`
    query StaticNewsletter {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
        additionalTitle
        additionalContent
        additionalPlaceholder
        additionalSuccess
      }
    }
  `)

  const route =
    Object.entries(routes)?.find(
      ([route, url]) => !route?.toLowerCase()?.includes("page") && url && location?.pathname?.includes(url)
    )?.[0] ||
    (location?.pathname === routes.HOMEPAGE && "HOMEPAGE")
  //@ts-ignore
  const active = newsletter?.enabled && route && newsletter[`template${route?.charAt(0)?.toUpperCase() + route?.slice(1)?.toLowerCase()}`]

  const handleActiveSubscribe = useCallback(
    state => {
      dispatch({
        type: "subscribe",
        payload: state,
      })
    },
    [dispatch]
  )

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setData]
  )

  const handleClose = useCallback(() => {
    storage.set(keys.newsletter, "hidden", newsletter?.expiry)
    handleActiveSubscribe(false)
  }, [keys, newsletter, handleActiveSubscribe, storage])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()

      const response = await callFunction("customerSubscribe", { ...data, acceptsMarketing: true, tags: ["newsletter"] })
      setSuccess("success" === response.status)
      if ("success" === response.status) handleClose()
      return { customer: response.body }
    },
    [callFunction, data, setSuccess, handleClose]
  )

  useEffect(() => {
    if (active && !storage.get(keys.newsletter) && !state.activeSubscribe) {
      setTimeout(() => handleActiveSubscribe(true), (newsletter?.delay || 0) * 1000)
    }
  }, [active, state.activeSubscribe, keys, newsletter, handleActiveSubscribe, storage])

  return {
    data,
    errors,
    loading,
    success,
    newsletter,
    handleClose,
    handleSubmit,
    handleChange,
    activeSubscribe: state.activeSubscribe,
  }
}
