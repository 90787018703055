import React from "react"
import Link from "@app/components/Link"
import { Logo } from "@app/theme/icons"

const Brand: React.FC = props => (
  <Link
    to="/"
    outline="none"
    _focus={{
      outline: "none",
    }}
  >
    <Logo width={["115px", "173px"]} height="auto" {...props} />
  </Link>
)

export default React.memo(Brand)
