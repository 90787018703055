import React from "react"
import { Box, Container, Divider, Heading, HStack, Stack, VStack } from "@chakra-ui/react"
import Copyright from "@app/components/Copyright"
import NavigationFooter from "@app/components/Navigation/Footer/NavigationFooter"
import SubscribeForm from "@app/components/Subscribe/SubscribeForm"
import SocialMedia from "./SocialMedia"
import NavigationTerms from "./Navigation/Terms/NavigationTerms"
import { DesktopPaymentsIcon, MobilePaymentsIcon } from "@app/theme/icons"

type Props = {
  location: any
}

const Footer: React.FC<Props> = ({ location }: any) => {
  return (
    <Box as="footer" bgColor="#efefef" color="grey.900" py={10}>
      <Container maxW="container.xxl" px={[4, 16]}>
        <Stack direction={["column", "row"]} pb={[4, "60px"]}>
          <Stack spacing="4" w={["100%", "40%"]}>
            <Heading size="h4" as="h4" mb={["4", "8"]}>
              Join today and get first access to exclusive offers, events and brand news.
            </Heading>
            <Stack spacing="4" direction={["column", "row"]}>
              <SubscribeForm location={location} />
            </Stack>
          </Stack>
          <VStack w={["100%", "60%"]} mt={0} alignItems={["flex-start", "flex-end"]}>
            <Box w={["100%", "60%"]} mt={[10, 0]} mb={[6, 10]}>
              <NavigationFooter />
            </Box>
            <SocialMedia />
          </VStack>
        </Stack>
        <Divider />
        <HStack w="100%" justifyContent="end" spacing={[0, 4]} alignItems="center" wrap={["wrap", "nowrap"]}>
          <Box w={["100%", "25%"]}>
            <NavigationTerms />
          </Box>
          <Box w={["100%", "50%"]}>
            <Box d={["none", "flex"]} justifyContent="center">
              <DesktopPaymentsIcon />
            </Box>
            <Box d={["flex", "none"]} justifyContent="center">
              <MobilePaymentsIcon />
            </Box>
          </Box>
          <Box w={["100%", "25%"]}>
            <Copyright />
          </Box>
        </HStack>
      </Container>
    </Box>
  )
}

export default React.memo(Footer)
