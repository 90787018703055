import React from "react"
import Meta from "@app/components/Meta"
import Main from "@app/components/Main"
import Header from "@app/components/Header/Header"
import Footer from "@app/components/Footer"
import { withLayout } from "@app/hoc/Layout"
import Breadcrumb from "@app/components/Breadcrumb"
import CartMini from "@app/components/Cart/CartMini"
import Subscribe from "@app/components/Subscribe/Subscribe"
import Global from "@app/theme/foundations/global"
import type { PageProps } from "@root/types/global"
import SizeGuideDrawer from "@app/components/SizeGuide/SizeGuideDrawer"

const Layout: React.FC<PageProps<any>> = ({ data, children, location }) => (
  <>
    <Global />
    <Meta data={data} location={location} />
    <Header data={data} location={location} />
    <CartMini />
    <Main>
      <Breadcrumb data={data} location={location} exclude={["/journal"]} />
      {children}
    </Main>
    <Subscribe location={location} />
    <Footer location={location} />
    <SizeGuideDrawer />
  </>
)

export default withLayout(Layout)
