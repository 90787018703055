import React from "react"
import { AspectRatio, Box, Button, Flex, Text, Image, GridItem, Grid } from "@chakra-ui/react"
import Link from "@app/components/Link"
import ProductPrice from "@app/components/Product/ProductPrice"
import { useCart } from "@app/hooks/useCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

type Props = {
  item: any
}

const CartMiniItem: React.FC<Props> = ({ item }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { loading: cartLoading, removeFromCart, updateQuantity } = useCart()
  const loading = cartLoading

  const selectedSize = item?.variant?.selectedOptions.find(option => option.name === "Size")?.value

  return (
    <Box mb={0}>
      <Flex mt={4} alignItems="center" justifyContent="space-between">
        <Box w={[160, 195]} flexShrink={0}>
          <AspectRatio ratio={1 / 1}>
            <>
              {item?.variant?.image && (
                <Image {...item.variant.image} fallbackSrc="https://plchldr.co/i/195x195?&amp;bg=EFEFEF&amp;fc=EFEFEF" />
              )}
            </>
          </AspectRatio>
        </Box>
        <Box w="50%" px={2}>
          <Link to={urlResolver(item?.variant?.product, routes.PRODUCT)?.url}>
            <Text size="13" fontWeight="600" mb={4}>
              {item.title}
            </Text>
          </Link>
          {selectedSize && (
            <Grid justify="space-between" templateColumns={"repeat(2, 1fr)"} mb={3}>
              <GridItem d="flex" alignItems="center">
                <Text size="12">Size</Text>
              </GridItem>
              <GridItem d="flex" justifyContent="center" alignItems="center">
                <Text size="12" textAlign="center">
                  {selectedSize}
                </Text>
              </GridItem>
            </Grid>
          )}
          <Grid justify="space-between" templateColumns={"repeat(2, 1fr)"} mb={3}>
            <GridItem d="flex" alignItems="center">
              <Text size="12">Quantity</Text>
            </GridItem>
            <GridItem>
              <Flex justifyContent="center" alignItems="center">
                <Button
                  height="12px"
                  minWidth={["6", "10"]}
                  variant="ghost"
                  px="1"
                  disabled={loading || item.quantity === 1}
                  onClick={() => updateQuantity(item?.variant?.id, item?.quantity - 1)}
                >
                  –
                </Button>
                <Text w={4} textAlign="center" size="12">
                  {item?.quantity}
                </Text>
                <Button
                  height="12px"
                  minWidth={["6", "10"]}
                  px="1"
                  variant="ghost"
                  disabled={loading || item.quantity >= item.variant.quantityAvailable}
                  onClick={() => updateQuantity(item?.variant?.id, item?.quantity + 1)}
                >
                  +
                </Button>
              </Flex>
            </GridItem>
          </Grid>
          <Grid justify="space-between" templateColumns={"repeat(2, 1fr)"} mb={2}>
            <GridItem d="flex" alignItems="center">
              <Text size="12">Price</Text>
            </GridItem>
            <GridItem d="flex" alignItems="center" justifyContent="center">
              <ProductPrice variant={item?.variant} small={true} />
            </GridItem>
          </Grid>
          <Button
            variant="link"
            disabled={loading}
            textTransform="unset"
            fontWeight="400"
            letterSpacing="0"
            onClick={() => removeFromCart(item?.variant?.id)}
            size="sm"
          >
            Remove
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default React.memo(CartMiniItem)
