import * as React from "react"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"

export const useHeader = (location: any): HeaderProps => {
  const [transparent, setTransparent] = React.useState<boolean>(true)
  const { state } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const isHomepage = location.pathname === routes.HOMEPAGE

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    function handleScroll() {
      const _transparent = isHomepage && !(state.activeMenu || state.activeSearch || state.activeCart || window.scrollY > 0)
      if (_transparent !== transparent) {
        setTransparent(_transparent)
      }
    }
    handleScroll()
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [transparent, isHomepage, state])

  return {
    transparent,
    isHomepage,
  }
}

export type HeaderProps = {
  transparent: boolean
  isHomepage: boolean
}
