import React, { useCallback, useEffect, useState } from "react"
import Link from "@app/components/Link"
import {
  Box,
  VStack,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useNavigation } from "@app/hooks/useNavigation"
import { useAppContext } from "@app/providers/app"
import { AccountIcon, HeartIcon } from "@app/theme/icons"
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"

const NavigationHeaderMobile: React.FC = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { headerMobile: items } = useNavigation()
  const { state, dispatch, headerOffset } = useAppContext()

  const [active, setActive] = useState([])

  const onClose = () => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }

  const onToggle = useCallback(
    (key: string) => {
      setActive(prevState => (prevState.includes(key) ? prevState.filter(item => key !== item) : [...prevState, key]))
    },
    [setActive]
  )

  useEffect(() => {
    if (!state?.activeMenu && active?.length > 0) setActive([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.activeMenu])

  return (
    <Drawer isOpen={state.activeMenu} onClose={onClose} placement="left">
      <DrawerContent
        color="black"
        sx={{
          "&": {
            top: `${headerOffset}px !important`,
          },
        }}
      >
        <DrawerBody px={6} pt={4} overflow="hidden" pos="relative">
          <VStack
            alignItems="unset"
            h="full"
            spacing={"10px"}
            transform={`translateX(${active?.length > 0 ? `-100%` : `0%`})`}
            transition="all 0.5s ease"
            overflowX="hidden"
            overflowY="auto"
          >
            {items?.length > 0 &&
              items?.map(({ type, title, url }) =>
                type !== "sub" ? (
                  <Text size="12" fontWeight="700" key={title} w="100%" as={props => <Link to={url} {...props} />} textStyle="navigation1">
                    {title}
                  </Text>
                ) : (
                  <Box key={title}>
                    <Button
                      title={title}
                      onClick={() => onToggle(title)}
                      variant="ghost"
                      w="full"
                      d="flex"
                      justifyContent="space-between"
                      p="0"
                      height="18px"
                    >
                      <Text size="12" fontWeight="700" textStyle="navigation1">
                        {title}
                      </Text>
                      <BiChevronRight />
                    </Button>
                  </Box>
                )
              )}
          </VStack>
          {items.map(
            ({ type, title, items }) =>
              type === "sub" && (
                <Box
                  key={title}
                  pos="absolute"
                  inset={0}
                  visibility={active?.includes(title) ? `visible` : `hidden`}
                  transform={`translateX(${active?.includes(title) ? `0%` : `100%`})`}
                  transition="all 0.5s ease"
                  overflowX="hidden"
                  overflowY="auto"
                  h="full"
                  bgColor="white"
                  px={6}
                >
                  <Button
                    title={title}
                    onClick={() => onToggle(title)}
                    w="full"
                    d="flex"
                    variant="ghost"
                    justifyContent="space-between"
                    p="0"
                  >
                    <BiChevronLeft />
                    <Text size="12" fontWeight="700" textStyle="navigation1">
                      {title}
                    </Text>
                    <Box width={"30px"} />
                  </Button>
                  <Accordion allowToggle>
                    {items?.map(({ items, type, title, url }) =>
                      type !== "sub" ? (
                        <AccordionItem key={title} py={1}>
                          <Text
                            size="12"
                            fontWeight="700"
                            key={title}
                            w="100%"
                            as={props => <Link to={url} {...props} />}
                            textStyle="navigation1"
                          >
                            {title}
                          </Text>
                        </AccordionItem>
                      ) : (
                        <AccordionItem key={title}>
                          <AccordionButton px="0" py={2} title={title}>
                            <Text flex="1" textAlign="left" size="12" fontWeight="700" textStyle="navigation1">
                              {title}
                            </Text>
                            <AccordionIcon fontSize={12} />
                          </AccordionButton>
                          <AccordionPanel px="0" py={2}>
                            {!!items?.length && (
                              <VStack spacing={2} alignItems="flex-start">
                                {items.map(({ title, url }, index) => (
                                  <Text as={Link} key={index} title={title} to={url} size="12" fontWeight="400" textStyle="navigation1">
                                    {title}
                                  </Text>
                                ))}
                              </VStack>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    )}
                  </Accordion>
                </Box>
              )
          )}
        </DrawerBody>
        <DrawerFooter w="100%" pb="10">
          <VStack borderTop="1px solid" borderColor="grey.300" pt="6" alignItems="flex-start" w="100%">
            <Text
              as={props => <Link to={routes.DASHBOARD} {...props} />}
              textStyle="navigation1"
              d="flex"
              alignItems="center"
              size="12"
              fontWeight="700"
            >
              <AccountIcon width="22" height="22" /> <Box ml="2">Login / Account</Box>
            </Text>
            <Text
              as={props => <Link to={routes.WISHLIST} {...props} />}
              textStyle="navigation1"
              d="flex"
              alignItems="center"
              size="12"
              fontWeight="700"
            >
              <HeartIcon width="22" height="22" /> <Box ml="2">Wishlist</Box>
            </Text>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(NavigationHeaderMobile)
