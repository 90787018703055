import React, { useCallback, useEffect, useRef } from "react"
import { Box, Flex, InputGroup, CloseButton } from "@chakra-ui/react"
import { SearchIcon } from "@chakra-ui/icons"
import { SensorStack, SearchInput, ResultList, useSearch } from "@usereactify/search"

import { useAppContext } from "@app/providers/app"
import { SearchFormResults } from "./SearchFormResults"

export const SearchForm: React.FC = () => {
  // const { showInstantSearchResults } = useSearch()
  const inputRef = useRef()
  const { dispatch, state } = useAppContext()
  const { searchQuery, setSearchQuery } = useSearch()

  const handleActiveSearch = useCallback(() => {
    if (searchQuery) {
      setSearchQuery("")
      const field = inputRef?.current?.querySelector("input")
      if (field) field?.focus()
    } else {
      dispatch({
        type: "search",
        payload: !state.activeSearch,
      })
    }
  }, [dispatch, inputRef, searchQuery, setSearchQuery, state])

  useEffect(() => {
    if (state.activeSearch) {
      const field = inputRef?.current?.querySelector("input")
      if (field) field?.select()
    }
  }, [state?.activeSearch])
  return state.activeSearch ? (
    <>
      <Flex w="full" justifyContent="space-between" borderBottom="1px solid #B1B1B1" mb={2}>
        <SearchIcon mr={4} alignSelf={"center"} />
        <Box flex={1}>
          <InputGroup
            ref={inputRef}
            bg="transparent"
            w="full"
            sx={{
              "& > *": {
                w: "full",
              },
              input: {
                h: 10,
                pl: 0,
                border: "none",
                fontSize: "lg",
                borderRadius: "none",
                bg: "transparent",
                borderColor: "",
                _focus: {
                  bg: "transparent",
                },
              },
            }}
          >
            <SearchInput placeholder="What are you looking for?" />
          </InputGroup>
        </Box>
        <CloseButton onClick={handleActiveSearch} aria-label={searchQuery ? "clear" : "close"} title={searchQuery ? "clear" : "close"} />
      </Flex>
      <SensorStack />
      <ResultList pageSize={4} renderResults={props => <SearchFormResults {...props} />} />
    </>
  ) : null
}
