import React from "react"
import { useCore } from "@app/hooks/useCore"
import { Box, Grid, Stack, Text, Link as CharkraLink } from "@chakra-ui/react"
import { useNavigation } from "@app/hooks/useNavigation"
import Link from "@app/components/Link"

const NavigationFooter: React.FC = () => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const { footer: items } = useNavigation()

  return isBrowser ? (
    <ErrorBoundary>
      <Grid lineHeight="18px" gridTemplateColumns={"1fr 1fr auto"} flex="1" gridColumnGap={4}>
        {items?.map(({ type, title, url, items }, index) =>
          type !== "sub" ? (
            <CustomLink key={index} to={url} style="primary">
              {title}
            </CustomLink>
          ) : (
            <Box key={index} minW="130px">
              <Text size="md" mb="4" textStyle="navigation2" whiteSpace="nowrap">
                {title}
              </Text>
              {!!items?.length && (
                <Stack>
                  {items.map(({ title, url }, index) => (
                    <CustomLink key={index} to={url} style="secondary">
                      {title}
                    </CustomLink>
                  ))}
                </Stack>
              )}
            </Box>
          )
        )}
      </Grid>
    </ErrorBoundary>
  ) : null
}

const CustomLink = ({ key, to, style, children }: { key: any; to: string; style: string; children: React.ReactNode }) => {
  const { globalActionLinks, toggleGlobalAction } = useNavigation()
  const isAction = globalActionLinks.filter(key => key === to)?.length || false

  const styleProps = {
    primary: {
      letterSpacing: "0.2em",
      size: "10",
      lineHeight: "16px",
      textStyle: "navigation2",
      _hover: { color: "grey.700" },
      whiteSpace: "nowrap",
      cursor: "pointer",
      mb: "3",
    },
    secondary: {
      whiteSpace: "nowrap",
      cursor: "pointer",
    },
  }

  return isAction ? (
    //@ts-ignore
    <CharkraLink key={key} onClick={() => toggleGlobalAction(to?.replace("#", ""), true)} {...styleProps[style]}>
      {children}
    </CharkraLink>
  ) : (
    //@ts-ignore
    <Link key={key} to={to} {...styleProps[style]}>
      {children}
    </Link>
  )
}

export default React.memo(NavigationFooter)
