import { useState, useEffect, useCallback, useMemo } from "react"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import useDeepCompareEffect from "use-deep-compare-effect"
import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCustomerContext } from "@app/providers/customer"
import { useRoutes } from "./useRoutes"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShop()
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    graphql: {
      queries: {
        GET_PRODUCT_AVAILABILITY,
        GET_COLLECTION_PRODUCT_COMPLETE,
        GET_PRODUCTS_BY_HANDLE,
        GET_COLLECTIONS_BY_HANDLE,
        GET_PRODUCT_RECOMMENDATIONS,
        GET_CART_RECOMMENDATIONS,
      },
    },
    helpers: { encodeShopifyId },
  } = useCore()

  const formatErrors = errors => errors?.map(({ message }) => message)

  const formatMoney = (amount: number, currency = "AUD") => {
    const formatted = new Intl.NumberFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      style: "currency",
      currency: currency,
    }).format(amount)

    return formatted.replace(".00", "")
  }

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      timeZone: "Australia/Melbourne",
    }).format(new Date(date))

  const imageUrl = (src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false
    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }

  const imageSrcSets = (src: string, size: string | number) =>
    src?.includes(`shopify.com`) &&
    [1, 500, 1000, 1500, 2000]
      .filter(set => !size || (size && size >= set))
      .map(set => `${imageUrl(src, set)} ${set}w`)
      .join(`,`)

  const onSale = (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = (item: any) => item?.handle || item?.shopify?.handle

  const edgeNormaliser = (item: any) => (item?.length ? item : item?.edges?.map(({ node }: { node: any }) => node) || [item] || [])

  const addressNormaliser = (address: any) => ({
    ...address,
    default: address?.id === customer?.defaultAddress?.id,
  })

  const orderNormaliser = (orders: any) =>
    edgeNormaliser(orders)?.map((order: any) => ({ ...order, lineItems: edgeNormaliser(order?.lineItems) }))

  const imageNormaliser = (image: any, size: string | number = "") => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
  })

  const videoNormaliser = (video: any, size: string | number = "") => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.previewImage || "", size),
    src: video?.sources
      ?.filter(({ format }: { format: string }) => format === "mp4")
      ?.sort((a: any, b: any) => (a.height < b.height ? 1 : -1))?.[0],
  })

  const modelNormaliser = (model: any, size: string | number = "") => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.previewImage || "", size),
    sources: model?.sources,
  })

  // const priceNormaliser = (presentmentPrices: any, field: string) =>
  //   Object.assign(
  //     {},
  //     ...edgeNormaliser(presentmentPrices)
  //       ?.filter((item: any) => item[field]?.currencyCode === (checkout?.currencyCode || shop?.currencyCode))
  //       .map((item: any) => ({
  //         ...item[field],
  //         local: formatMoney(item[field]?.amount, checkout?.currencyCode || shop?.currencyCode),
  //         afterpay: formatMoney((field ? item[field] : item)?.amount / 4),
  //       }))
  //   )

  const priceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        ?.filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const priceFieldNormaliser = (item: any, field = "") => {
    const price = field && item?.[field] ? item[field] : item
    return {
      amount: `${price?.amount}`,
      local: formatMoney(price?.amount),
      afterpay: formatMoney(price?.amount / 4),
      currencyCode: `${price?.currencyCode || price?.currency_code}`,
    }
  }

  const productNormaliser = (product: any) => {
    return {
      ...product,
      collections:
        edgeNormaliser(product?.collections)?.map((collection: any) => ({
          ...collection,
          image: imageNormaliser(collection?.image),
        })) || [],
      images:
        product?.images?.edges?.length > 0
          ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
          : product?.media?.edges?.length > 0
          ? edgeNormaliser(product?.media)
              ?.filter((media: any) => media?.mediaContentType === "IMAGE")
              ?.map((media: any) => imageNormaliser(media?.image))
          : [],
      media: edgeNormaliser(product?.media)?.map((media: any) =>
        media?.mediaContentType === "VIDEO"
          ? videoNormaliser(media)
          : media?.mediaContentType === "IMAGE"
          ? imageNormaliser(media?.image)
          : null
      ),
      metafields: edgeNormaliser(product?.metafields),
      models: edgeNormaliser(product?.media)
        ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
        ?.map((media: any) => modelNormaliser(media)),
      presentmentPriceRanges: {
        minVariantPrice: priceNormaliser(product?.presentmentPriceRanges, "minVariantPrice"),
        maxVariantPrice: priceNormaliser(product?.presentmentPriceRanges, "maxVariantPrice"),
      },
      variants: variantsNormaliser(product?.variants) || [],
      videos: edgeNormaliser(product?.media)
        ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
        ?.map((media: any) => videoNormaliser(media)),
    }
  }

  const variantsNormaliser = (variants: any) => edgeNormaliser(variants)?.map(variantNormaliser)

  const variantNormaliser = (variant: any) => ({
    ...variant,
    ...(variant?.image && { image: imageNormaliser(variant?.image) }),
    ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
    presentmentPrices: edgeNormaliser(variant?.presentmentPrices),
    priceV2: priceNormaliser(variant?.presentmentPrices, "price"),
    compareAtPriceV2: priceNormaliser(variant?.presentmentPrices, "compareAtPrice"),
  })

  const adminProductNormaliser = (product: any, config?: any) => {
    if (product?.product_type === undefined) return product
    return {
      ...product,
      url: routes.PRODUCT,
      availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
      id: encodeShopifyId(product?.id, "Product"),
      images: product?.images?.map((image: any) => imageNormaliser(image, config?.imageSize || false)) || [],
      legacyId: product?.id,
      productType: product?.product_type,
      presentmentPriceRanges: {
        minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
        maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
      },
      variants: product?.variants?.map((variant: any) => ({
        ...variant,
        availableForSale: variant?.available,
        id: encodeShopifyId(variant?.id, "ProductVariant"),
        legacyId: variant?.id,
        presentmentPrices: adminPresentmentPriceNormaliser(variant?.presentment_prices),
        priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
        compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
      })),
    }
  }

  const adminPriceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...presentmentPrices
        ?.filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currency_code === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const adminPresentmentPriceNormaliser = (presentment_prices: any) =>
    presentment_prices?.map((presentmentPrice: any) => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const collectionNormaliser = (collection: any) => ({
    ...collection,
    id: parseInt(collection?.shopify?.id) || collection?.id,
    handle: collection?.shopify?.handle || collection?.handle,
    image: imageNormaliser(collection?.image),
    ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
    products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
  })

  const checkoutNormaliser = (checkout: any) => ({
    ...checkout,
    lineItems:
      checkout?.lineItems?.edges?.map(({ node }: { node: any }) => ({
        ...node,
        variant: {
          ...node?.variant,
          priceV2: Object.assign(
            {},
            ...node?.variant?.presentmentPrices?.edges
              ?.filter(({ node }: { node: any }) => node?.price?.currencyCode === checkout?.currencyCode)
              .map(({ node }: { node: any }) => node?.price)
          ),
          compareAtPriceV2: Object.assign(
            {},
            ...node?.variant?.presentmentPrices?.edges
              ?.filter(({ node }: { node: any }) => node?.compareAtPriceV2?.currencyCode === checkout?.currencyCode)
              .map(({ node }: { node: any }) => node?.compareAtPriceV2)
          ),
          image: imageNormaliser(node?.variant?.image, 500),
        },
      })) || [],
    discountApplications: edgeNormaliser(checkout.discountApplications),
  })

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstProducts = 0,
    firstVariants = 0,
    handle = "",
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstProducts,
        firstVariants,
        handle,
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getCollections = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
    handles = [],
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTIONS_BY_HANDLE(handles),
      variables: {
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
      },
    })

    //@ts-ignore
    return handles?.map(handle => collectionNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProduct = async ({ handle }) => {
    const { data } = await client.query({
      query: GET_PRODUCT_AVAILABILITY,
      variables: { handle },
    })
    return data.product ? productNormaliser(data?.product) : null
  }

  const getProducts = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
    handles = [],
  }) => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(handles),
      variables: {
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
      },
    })

    //@ts-ignore
    return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getRecommendations = async ({
    productId,
    first = 4,
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 10,
    firstMetafields = 0,
    firstVariants = 0,
  }) => {
    const { data } = await client.query({
      query: GET_PRODUCT_RECOMMENDATIONS,
      variables: {
        id: productId,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
      },
    })

    return data?.recommendations?.filter((_, index) => index < first).map(productNormaliser)
  }

  const getCartRecommendations = async ({ ids, first = 4, firstImages = 10, firstVariants = 0 }) => {
    const { data } = await client.query({
      query: GET_CART_RECOMMENDATIONS,
      variables: {
        ids: ids,
        firstImages,
        firstVariants,
      },
    })

    return data?.recommendations?.filter((_, index) => index < first).map(productNormaliser)
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    edgeNormaliser,
    imageNormaliser,
    checkoutNormaliser,
    priceNormaliser,
    orderNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProduct,
    getProducts,
    getRecommendations,
    getCartRecommendations,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct, activeVariant, setActiveVariant } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()

  const { trackProductView } = useAnalytics()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes(routes.PRODUCT)) {
        let currentProduct = product
        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.log(e.message)
        }
        if (!activeProduct || activeProduct?.id !== currentProduct?.id) {
          setActiveProduct(currentProduct)
          trackProductView(currentProduct, currentProduct?.variants?.[0], false)
        }
      } else {
        if (activeProduct !== null) setActiveProduct(null)
        if (activeVariant !== null) setActiveVariant(null)
      }
    },
    [activeProduct, setActiveProduct, activeVariant, setActiveVariant, trackProductView, routes.PRODUCT]
  )

  return { activeProduct, selectProduct }
}

export const useShopifyFirstAvailable = product => {
  const variant = product?.variants?.find(({ availableForSale }) => availableForSale) || product?.variants?.[0]
  return { variant }
}

export const getVariantBySelectedOptions = (selectedOptions, variants) =>
  variants?.find(
    ({ selectedOptions: variantOptions }) =>
      variantOptions?.filter(
        variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
      )?.length === selectedOptions?.length
  )

export const useShopifyVariants = ({
  firstAvailable = false,
  useParameter = false,
  loading = false,
  product,
  preSelectedVariantTitle = undefined,
}) => {
  const { checkout } = useCheckoutContext()
  const { trackProductView } = useAnalytics()
  const { activeVariant, activeProduct, setActiveVariant } = useAppContext()
  const {
    settings: { params },
  } = useConfigContext()
  const {
    helpers: { encodeShopifyId, decodeShopifyId, isBrowser },
  } = useCore()

  const { getUrlParameter, setUrlParameter } = useRoutes()

  const [localActiveVariant, setLocalActiveVariant] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])

  // reset activeVariant and selectedOptions if product changes
  useDeepCompareEffect(() => {
    setLocalActiveVariant(null)
    setSelectedOptions([])
  }, [product?.variants])

  const { variants, options } = product

  // get variant id from URL params
  const currentVariantId = getUrlParameter(params?.variant)

  // get default variant if useParameter or firstAvailable is enabled
  const defaultVariant =
    (useParameter && variants?.find(variant => variant?.id === encodeShopifyId(currentVariantId, "ProductVariant"))) ||
    variants?.find(variant => variant?.title === preSelectedVariantTitle) ||
    (firstAvailable && variants?.find(({ availableForSale }) => availableForSale))

  //if there is a default variant, set it to active variant
  useEffect(() => {
    if (defaultVariant && !localActiveVariant) {
      setActiveVariant(defaultVariant)
      setLocalActiveVariant(defaultVariant)
      setSelectedOptions(defaultVariant.selectedOptions)
    }
  }, [defaultVariant, localActiveVariant, setActiveVariant])

  // select option with only 1 value by default, e.g. Colour
  useEffect(() => {
    if (selectedOptions?.length === 0 && options?.length > 0) {
      options?.forEach(option => {
        if (option?.values.length === 1) {
          setSelectedOptions(prev => [
            ...prev.filter(prevOption => prevOption.name !== option.name),
            {
              name: option.name,
              value: option.values[0],
            },
          ])
        }
      })
    }
  }, [selectedOptions, options])

  // reset selectedOptions if options changes, for quick view products colours changes
  useDeepCompareEffect(() => {
    if (options?.length > 0) {
      setSelectedOptions([])
    }
  }, [options])

  // update selected variant if selectedOptions changes, selectedOption is an array. useEffect is not able to compare the objects in an array
  useDeepCompareEffect(() => {
    if (selectedOptions.length > 0 && selectedOptions.length === options.length) {
      const variant = getVariantBySelectedOptions(selectedOptions, variants)
      if (variant) {
        setActiveVariant(variant)
        setLocalActiveVariant(variant)
      }
    }
  }, [selectedOptions])

  // Navigate to the active variant(add the URL params) if useParameter is enabled
  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && isBrowser) {
      if (currentVariantId !== encodeShopifyId(activeVariant.id, "ProductVariant") && activeVariant.id !== defaultVariant.id) {
        window.history.replaceState(
          null,
          window.document.title,
          setUrlParameter(params.variant, decodeShopifyId(activeVariant.id, "ProductVariant"))
        )
      }

      if (checkout?.currencyCode && activeVariant?.id && activeVariant?.priceV2?.amount && !loading)
        trackProductView(activeProduct, activeVariant, false)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant?.id, activeVariant?.priceV2?.amount, checkout?.currencyCode, loading])

  // handle variant change
  const handleOption = useCallback(option => {
    setSelectedOptions(prev => [...prev.filter(prevOption => prevOption.name !== option.name), option])
  }, [])

  return { localActiveVariant, activeVariant, handleOption, selectedOptions }
}

export const useShopifyPrice = (variant: any) => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const price = variant?.priceV2?.amount ? Number(variant?.priceV2?.amount) : 0
    const compareAtPrice = variant?.compareAtPriceV2?.amount ? Number(variant?.compareAtPriceV2?.amount) : 0
    const onSale = !!compareAtPrice && !!price && compareAtPrice > price
    const formattedPrice = formatMoney(price, currencyCode)
    const formattedCompareAtPrice = formatMoney(compareAtPrice, currencyCode)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedCompareAtPrice,
    }
  }, [variant, checkout?.currencyCode, formatMoney])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const priceRangeMin = product?.presentmentPriceRanges?.minVariantPrice || product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.presentmentPriceRanges?.maxVariantPrice || product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax
        ? `${formatMoney(priceMin, currencyCode)}${separator}${formatPrice(`${priceMax}`)}`
        : formatMoney(priceMin, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
    }
  }, [product, checkout?.currencyCode, separator, formatMoney, formatPrice])
}
