import React from "react"
import Link from "@app/components/Link"
import { Text, LinkBox, AspectRatio, Image, Box, LinkOverlay, Flex } from "@chakra-ui/react"
import { ElasticProduct, useAnalytics as useReactifySearchAnalytics } from "@usereactify/search"
import { useShopify } from "@app/hooks/useShopify"
import { useRoutes } from "@app/hooks/useRoutes"
import ProductPrice from "@app/components/Product/ProductPrice"

export const SearchFormItem: React.FC<{ product: ElasticProduct }> = ({ product }) => {
  const { adminProductNormaliser } = useShopify()
  const { urlResolver } = useRoutes()
  const reactifySearchAnalytics = useReactifySearchAnalytics()
  const imageSize = 400
  const item = adminProductNormaliser(product, { imageSize })
  const link = urlResolver(product)

  const handleTrackingClick = React.useCallback(() => {
    reactifySearchAnalytics.track({
      eventName: "clickProduct",
      payload: {
        elasticProduct: {
          id: item.id,
          title: item.title,
        },
      },
    })
  }, [item])

  return (
    <Link to={product.url} title={link.title}>
      <LinkBox onClick={handleTrackingClick}>
        <AspectRatio ratio={1} w="100%" objectFit="cover" mb="16px">
          <Image
            src={item.images[0]?.src}
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio>
        {/* <AspectRatio d={["block", "none"]} ratio={1} w="100%" objectFit="cover" mb={[4, 6]}>
          <Image
            src={mobileImages[0]?.src}
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio> */}
        <Box textAlign="left">
          <LinkOverlay as={Link} to={product?.url || urlResolver(item, routes.PRODUCT)?.url} title={item?.title}>
            <Text as="h3" textStyle="paragraph" fontSize={"14px"} mb={2} lineHeight="21px">
              {item?.title}
            </Text>
          </LinkOverlay>
          <Flex justify="left">
            <ProductPrice variant={item.variants?.[0]} small={true} />
          </Flex>
        </Box>
      </LinkBox>
    </Link>
  )
}
