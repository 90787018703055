import { useState, useCallback } from "react"
import { useLocationContext } from "@app/providers/location"
import { useFirebaseContext } from "@app/providers/firebase"

export const useFunctions = (initialData = {}) => {
  const { store } = useLocationContext()
  const { functions } = useFirebaseContext()

  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const callFunction = useCallback(
    async (name, data) => {
      //@ts-ignore
      const request = functions.httpsCallable(name)
      try {
        setLoading(true)
        setErrors([])
        const response = await request({ shop: store.shopName, ...data })
        setLoading(false)
        return { ...response.data }
      } catch (err) {
        setLoading(false)
        setErrors([err.message])
        return { status: "error", body: err.message }
      }
    },
    [functions, store]
  )

  return { callFunction, loading, errors, data, handleChange }
}
