import React from "react"
import { Global as EmotionGlobal } from "@emotion/react"

export const Global: React.FC = () => (
  <EmotionGlobal
    styles={`
      @font-face {
        font-family: "Miller Display";
        src: url("/fonts/MillerDisplay-Regular.woff") format("woff");
        font-weight: 400;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Miller Display";
        src: url("/fonts/MillerDisplay-Italic.woff") format("woff");
        font-weight: 400;
        font-style: italic;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Miller Display";
        src: url("/fonts/MillerDisplay-Light.woff") format("woff");
        font-weight: 200;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Miller Display";
        src: url("/fonts/MillerDisplay-Semibold.woff") format("woff");
        font-weight: 500;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Miller Display";
        src: url("/fonts/MillerDisplay-SemiboldItal.woff") format("woff");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Mark Pro";
        src: url("/fonts/MarkPro-Book.woff") format("woff");
        font-weight: 400;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Mark Pro";
        src: url("/fonts/MarkPro-Normal.woff") format("woff");
        font-weight: 500;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Mark Pro";
        src: url("/fonts/MarkPro-Medium.woff") format("woff");
        font-weight: 600;
        font-display: swap;
      }
      
      @font-face {
        font-family: "Mark Pro";
        src: url("/fonts/MarkPro-Bold.woff") format("woff");
        font-weight: 800;
        font-display: swap;
      }

      .grecaptcha-badge {
        display: none !important;
      }
      .flex-start {
        align-items: flex-start;
      }
    `}
  />
)

export default Global
