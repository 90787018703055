export const Accordion = {
  baseStyle: {
    container: {
      borderColor: "grey.300",
    },
    button: {
      fontSize: "base",
      fontWeight: "bold",
      px: 0,
      py: 4,
      _focus: {
        boxShadow: "none",
        outline: "none",
        bg: "none",
      },
      _hover: {
        bg: "none",
      },
    },
    panel: {
      px: 0,
    },
  },
}
