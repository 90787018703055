import React from "react"
import { Box, IconButton } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { CrossIcon, MenuIcon } from "@app/theme/icons"
import { useMediaMobile } from "@app/hooks/useMedia"

const NavigationHeaderWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()
  const isMobile = useMediaMobile()

  const onToggle = () => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }

  return isMobile ? (
    <Box display={["block", "none"]}>
      <IconButton
        justifyContent="flex-start"
        variant="menu"
        aria-label="Manage account"
        icon={state.activeMenu ? <CrossIcon height="20px" width="20px" /> : <MenuIcon height="20px" width="20px" />}
        onClick={onToggle}
      />
    </Box>
  ) : null
}

export default React.memo(NavigationHeaderWidget)
