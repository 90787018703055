import { useStaticQuery, graphql } from "gatsby"
import React from "react"

export const useAnnouncement = (): AnnouncementHook => {
  const [index, setIndex] = React.useState<number>(0)
  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        hide
        duration
        announcements {
          title
          link {
            link
            title
            external
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    let timeout: NodeJS.Timeout
    if (data) {
      const items = data.announcements || []
      const duration = (data.duration || 10) * 1000
      if (items.length) {
        timeout = setTimeout(() => {
          /** Increase the index every {duration} or set it to 0 when on the last item */
          setIndex(prev => (prev >= items.length - 1 ? 0 : prev + 1))
        }, duration)
      }
    }

    return function cleanup() {
      clearTimeout(timeout)
    }
  }, [data, index])

  /** Set the current item to display */
  const item = data?.announcements?.[index]
  const hide = !!data?.hide
  return {
    item,
    hide,
  }
}

type AnnouncementHook = {
  item: Announcement
  hide: boolean
}

type Announcement = GatsbyTypes.Maybe<
  Pick<GatsbyTypes.SanityAnnouncement, "title"> & {
    readonly link: GatsbyTypes.Maybe<Pick<GatsbyTypes.SanityLink, "link" | "external">>
  }
>
