export const colors = {
  brand: {
    primary: "#000000",
  },
  grey: {
    900: "#000000",
    800: "#333132",
    700: "#717171",
    500: "#B1B1B1",
    300: "#E0E0E0",
    200: "#EFEFEF",
    100: "rgba(99,102,106,.07)",
  },
  red: {
    500: "#C51712",
  },
}
