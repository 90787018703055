export const Select = {
  parts: ["field", "icon"],
  baseStyle: {
    field: {
      background: "transparent",
      border: 0,
      textTransform: "uppercase",
      fontSize: "10px",
    },
  },
  sizes: {},
  variants: {
    currency: {
      field: {
        letterSpacing: "0.2em",
        fontSize: "10px",
        fontWeight: "600",
        width: "50px",
        h: "14px",
        cursor: "pointer",
        p: 0,
      },
    },
    flushed: {
      field: {
        borderColor: "grey.500",
        marginBottom: 2,
        _hover: {
          borderColor: "grey.700",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "grey.700",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.500",
          boxShadow: "none",
          bg: "grey.mid",
          color: "grey.500",
          opacity: 1,
        },
        _invalid: {
          borderColor: "red.500",
          boxShadow: "none",
          color: "red.500",
        },
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
}
