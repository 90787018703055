import { useCore } from "@app/hooks/useCore"

const parentResolver = (parentUrls, item) => {
  if (item?.parentCollection?.shopify?.handle) {
    parentUrls.unshift(`/${item?.parentCollection?.shopify?.handle}`)
    parentResolver(parentUrls, item.parentCollection)
  }
}

export const useRoutes = () => {
  const {
    helpers: { getUrlParameter, setUrlParameter },
  } = useCore()

  const urlResolver = (source: any, route = "") => {
    const urlParts = []
    const parentUrls = []
    const type = source?._type || source?.document?._type
    const item = source?.[type] || source?.document || source
    const external = source?.external || false
    const url = item?.url || source?.link || ""
    const title = source?.title || item?.title || ""
    const handle = item?.meta?.canonicalUrl || item?.handle?.current || item?.shopify?.handle || item?.handle || ""

    if (url?.length) urlParts.push(url)
    if (route?.length && !url?.length) urlParts.push(route)

    parentResolver(parentUrls, item)

    if (parentUrls.length) {
      urlParts.push(...parentUrls)
    }

    if (handle?.length) urlParts.push(`/${handle}`)

    const completeUrl = urlParts?.[0] !== "/home" ? urlParts.join("") || "/" : "/"

    return {
      title,
      url: completeUrl,
      external,
    }
  }

  return {
    urlResolver,
    getUrlParameter,
    setUrlParameter,
  }
}
