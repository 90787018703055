import { useState, useEffect } from "react"

import { useCore } from "@app/hooks/useCore"

export const useDom = () => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const [dom, setDom] = useState({
    width: undefined,
    height: undefined,
    isMedium: true,
    isLarge: true,
  })

  useEffect(() => {
    const handleResize = () => {
      setDom({
        width: window.innerWidth,
        height: window.innerHeight,
        isMedium: window.innerWidth >= 768,
        isLarge: window.innerWidth >= 1024,
      })
    }

    if (isBrowser) {
      window.addEventListener("resize", handleResize)
      handleResize()
    }

    return () => isBrowser && window.removeEventListener("resize", handleResize)
  }, [isBrowser])

  return { dom }
}
