import { useCore } from "@app/hooks/useCore"
import { useDom } from "@app/hooks/useDom"
import React, { useState, useReducer, useCallback, useRef, useEffect } from "react"

import config from "@root/config.default.js"

type ContextProps = {
  state: {
    activeMenu: boolean
    activeCart: boolean
    activeSearch: boolean
    activeSubscribe: boolean
    activeSizeguide: boolean
  }
  dispatch: React.Dispatch<any>
  headerRef: any
  headerUpperRef: any
  headerOffset: number
  setHeaderOffset: (state: number) => void
  activeStore: any
  setActiveStore: (state: any) => void
  activeOptions: any | null
  setActiveOptions: (state: any) => void
  activeVariant: any | null
  setActiveVariant: (state: any) => void
  activeProduct: any | null
  setActiveProduct: (state: any) => void
  activeCollection: any | null
  setActiveCollection: (state: any) => void
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

export const AppProvider: React.FC = ({ children }) => {
  const headerRef = useRef()
  const headerUpperRef = useRef()
  const { dom } = useDom()
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    stores,
    settings: { keys },
    services: { shopify },
  } = config as any

  const store = stores[storage.get(keys.shopify ?? "") || shopify.defaultShopName]

  const [headerOffset, setHeaderOffset] = useState(0)
  const [activeStore, setActiveStore] = useState(store)
  const [activeOptions, setActiveOptions] = useState([])
  const [activeVariant, setActiveVariant] = useState(null)
  const [activeProduct, setActiveProduct] = useState(null)
  const [activeCollection, setActiveCollection] = useState(null)

  const initialValues = {
    activeMenu: false,
    activeCart: false,
    activeSearch: false,
    activeSubscribe: false,
    activeSizeguide: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...initialValues }
      case "menu":
        return {
          ...state,
          activeMenu: action.payload,
          activeSearch: false,
          activeCart: false,
          activeSubscribe: false,
          activeSizeguide: false,
        }
      case "cart":
        return {
          ...state,
          activeCart: action.payload,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
          activeSizeguide: false,
        }
      case "search":
        return {
          ...state,
          activeSearch: action.payload,
          activeCart: false,
          activeMenu: false,
          activeSubscribe: false,
          activeSizeguide: false,
        }
      case "subscribe":
        return {
          ...state,
          activeSubscribe: action.payload,
          activeSearch: false,
          activeCart: false,
          activeMenu: false,
          activeSizeguide: false,
        }
      case "sizeguide":
        return {
          ...state,
          activeSizeguide: action.payload,
          activeMenu: false,
          activeSearch: false,
          activeCart: false,
          activeSubscribe: false,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues)

  const calculateOffset = useCallback(() => {
    const upperHeight =
      window?.scrollY >= headerUpperRef?.current?.clientHeight ? 0 : headerUpperRef?.current?.clientHeight - window?.scrollY

    const height = (headerRef?.current?.clientHeight || 0) + (upperHeight || 0)

    if (height !== headerOffset) setHeaderOffset(height)
  }, [headerOffset, headerRef, headerUpperRef, setHeaderOffset])

  useEffect(() => {
    isBrowser && calculateOffset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dom.height, dom.width, state?.activeCart, state?.activeSearch, state?.activeMenu, state?.activeFilters])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      headerRef,
      headerUpperRef,
      headerOffset,
      setHeaderOffset,
      activeStore,
      setActiveStore,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
    }),
    [state, headerOffset, activeStore, activeOptions, activeVariant, activeProduct, activeCollection]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...React.useContext(AppContext) } as ContextProps)
