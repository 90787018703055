import React, { useState, useRef } from "react"
import { useCore } from "@app/hooks/useCore"
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Heading,
  Text,
  InputProps,
  VisuallyHidden,
  IconButton,
} from "@chakra-ui/react"
import { ArrowRightMinimalIcon } from "@app/theme/icons"
import ReCAPTCHA from "react-google-recaptcha"
import { useNewsletter } from "@app/hooks/useNewsletter"

type Props = InputProps & {
  initialRef?: any
  newsletter?: boolean
  location: any
  styleType: string
}

const SubscribeForm: React.FC<Props> = ({ initialRef, location, newsletter = false, styleType, ...rest }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { data, errors, handleSubmit, handleChange, loading, success } = useNewsletter(location)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const recaptchaRef = useRef()

  const handleFormSubmit = async (event: any) => {
    event.preventDefault()
    if (!recaptchaRef) {
      return
    }
    if (!recaptchaToken) {
      const token = await recaptchaRef.current.executeAsync()
      setRecaptchaToken(token)
    }
    setTimeout(() => {
      if (recaptchaToken) {
        handleSubmit(event)
      }
    }, 10)
  }

  return !success ? (
    <Box as="form" onSubmit={handleFormSubmit} w="100%">
      <Stack
        spacing={newsletter ? 2 : 5}
        direction={newsletter ? "column" : "row"}
        borderBottomWidth={newsletter ? "none" : "1px"}
        borderColor={styleType && styleType == "minimal" ? "#707071" : "#333132"}
      >
        <FormControl
          id="email"
          value={data?.email}
          onChange={handleChange}
          isRequired
          css={{
            "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
              WebkitBoxShadow: newsletter ? "0 0 0 30px #fff inset !important" : "0 0 0 30px #efefef inset !important",
            },
            "input:-webkit-autofill": {
              WebkitTextFillColor: "black !important;",
            },
          }}
        >
          <VisuallyHidden>
            <FormLabel>Email address</FormLabel>
          </VisuallyHidden>
          <Input
            variant={newsletter ? "flushed" : "unstyled"}
            height="100%"
            name="email"
            type="email"
            ref={initialRef}
            placeholder={styleType && styleType == "minimal" ? "Email" : "Enter your email address"}
            fontSize="14px"
            textAlign={newsletter ? "center" : "left"}
            paddingBottom={newsletter ? "2" : "0"}
            _placeholder={{
              color: "grey.700",
              textAlign: newsletter ? "center" : "left",
            }}
            {...rest}
          />
        </FormControl>

        {styleType && styleType == "minimal" ? (
          <IconButton
            aria-label="submit"
            type="submit"
            isDisabled={loading}
            isLoading={loading}
            _hover={{ color: "grey.700" }}
            variant="ghost"
            size="md"
            icon={<ArrowRightMinimalIcon />}
            minW="unset"
            maxW="21px"
          />
        ) : (
          <Button
            type="submit"
            variant={newsletter ? "ghost" : "subscribe"}
            flexShrink={0}
            fontSize="12px"
            fontWeight="600"
            width="auto"
            isDisabled={loading}
            isLoading={loading}
            _hover={{ color: "grey.700" }}
          >
            Sign up
          </Button>
        )}
      </Stack>
      {isBrowser && <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le89uweAAAAALeaigPPje8S4AzcABfeT94e85_O" />}

      {errors?.length > 0 && (
        <FormControl id="error" isInvalid>
          {errors?.map(error => (
            <FormErrorMessage key={error?.toString()}>{error?.toString()}</FormErrorMessage>
          ))}
        </FormControl>
      )}
    </Box>
  ) : (
    <>
      {styleType && styleType == "minimal" ? (
        <Text textStyle="paragraph2" size="14">
          YOU&apos;RE ON THE LIST
        </Text>
      ) : (
        <Text>Thanks for subscribing!</Text>
      )}
    </>
  )
}

export default React.memo(SubscribeForm)
