import React from "react"
import { VStack, Text } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import CartMiniItem from "./CartMiniItem"

const CartMiniList: React.FC = () => {
  const { checkout, count } = useCheckoutContext()
  return (
    <VStack spacing={8} alignItems={"stretch"} justifyContent="flex-start">
      {count > 0 ? (
        <>
          {checkout?.lineItems?.map((item: any) => (
            <CartMiniItem key={item?.id} item={item} />
          ))}
        </>
      ) : (
        <Text textAlign="center">You don&#39;t have any items in your cart.</Text>
      )}
    </VStack>
  )
}

export default React.memo(CartMiniList)
