import React, { useState, useEffect, useCallback } from "react"
import { useCore } from "@app/hooks/useCore"
import config from "@root/config.default.js"

type ContextProps = StoreLocation & {
  loading: boolean
}

export const LocationContext = React.createContext<ContextProps | undefined>(undefined)

export const LocationProvider: React.FC = ({ children }) => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    stores,
    settings: { keys },
    services: { location, shopify },
  } = config as BaseConfig

  const store = stores[storage.get(keys.shopify) || shopify.defaultShopName]

  const [settings, setSettings] = useState({
    store,
    country: storage.get(keys.country) || "",
    location: storage.get(keys.location) || "",
    locations: Object.values(stores).map((store: any) => store.siteLocation),
    locating: !storage.get(keys.country)?.length,
    visitor: !store.siteCountries?.includes(storage.get(keys.country)),
  })

  const [loading, setLoading] = useState(false)

  const updateLocation = useCallback(
    async (countryCode: string, updateCountry = false) => {
      setLoading(true)
      const newStore: Store = Object.values(stores).find(store => store.siteCountries.includes(countryCode)) || store

      await setSettings(prevState => ({
        ...prevState,
        store: newStore,
        country: updateCountry ? countryCode : settings.country,
        location: countryCode,
        locating: false,
        visitor: location.forceRegion ? !newStore.siteCountries?.includes(settings.country) : false,
      }))
      setLoading(false)
    },
    [store, stores, location, settings]
  )

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      ...settings,
      updateLocation,
      loading,
    }),
    [settings, updateLocation, loading]
  )

  useEffect(() => {
    if (settings.store && settings.country && settings.location) {
      storage.set(keys.country, settings.country)
      storage.set(keys.location, settings.location)
      storage.set(keys.shopify, settings.store.shopName)
    } else {
      try {
        fetch(location.serviceUrl)
          .then(res => res.json())
          .then(result => updateLocation(result[location.countryFieldKey] || store.siteLocation, true))
          .catch(() => updateLocation(store.siteLocation, true))
      } catch (e) {
        console.warn("Error locating country")
      }
    }
  }, [store, settings, storage, location, keys, updateLocation])

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>
}

export const useLocationContext = (): ContextProps => ({ ...React.useContext(LocationContext) } as ContextProps)
