import React from "react"
import { Box, Table as ChakraTable, Tr, Th, Td, Tbody, Thead, BoxProps } from "@chakra-ui/react"

type Props = GatsbyTypes.SanityTable & BoxProps

export const Table: React.FC<Props> = React.memo(({ rows, ...props }) =>
  rows && rows?.length > 0 ? (
    <Box {...props} overflowX="auto" overflowY="hidden">
      <ChakraTable>
        <Thead>
          <Tr>
            {rows[0]?.cells?.map((cell, index) => {
              return (
                <Th
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: cell?.replace("//", "<br />").replace("(", "<span class='metrics'>(")?.replace(")", ")</span>"),
                  }}
                />
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rows.slice(1)?.map((row, rowIndex) => (
            <Tr key={row?._key}>
              {row?.cells?.map((cell, index: number) => (
                <Td
                  key={index}
                  dangerouslySetInnerHTML={{ __html: cell?.replace("//", "<br />") }}
                  borderBottom={rowIndex === rows.length - 2 ? "none" : "1px solid #E0E0E0"}
                />
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </Box>
  ) : null
)
