import { useState, useCallback } from "react"
import { useMutation } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCheckoutContext } from "@app/providers/checkout"
import { useAppContext } from "@app/providers/app"

export const useCart = () => {
  const {
    graphql: {
      mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
    },
  } = useCore()
  const { checkout, id: checkoutId, saveCheckout } = useCheckoutContext()
  const { checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { dispatch } = useAppContext()

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const addToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)
      let alreadyInCart = false

      const lineItems =
        checkout?.lineItems?.map((lineItem: any) => {
          if (lineItem?.variant?.id === variantId) {
            alreadyInCart = true
            return {
              customAttributes: [
                ...lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                  key,
                  value,
                })),
                ...(customAttributes || []),
              ],
              quantity: lineItem?.quantity + quantity,
              variantId,
            }
          }
          return {
            customAttributes: lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
              key,
              value,
            })),
            quantity: lineItem?.quantity,
            variantId: lineItem?.variant?.id,
          }
        }) || []

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems: [...(alreadyInCart ? lineItems : [...lineItems, { quantity, variantId, customAttributes }])],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        await saveCheckout(data?.checkout)
        dispatch({
          type: "cart",
          payload: true,
        })
      }
      setLoading(false)

      trackCartUpdate("add", variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [checkout?.lineItems, lineItemsReplace, checkoutId, trackCartUpdate, checkoutNormaliser, saveCheckout, dispatch]
  )

  const removeFromCart = useCallback(
    async variantId => {
      setLoading(true)
      const quantity =
        checkout?.lineItems
          .filter(({ variant }: { variant: any }) => variant.id === variantId)
          .map(({ quantity }: { quantity: number }) => quantity)[0] || 1
      trackCartUpdate("remove", variantId, quantity, checkout?.lineItems)

      const lineItems = checkout?.lineItems
        .filter((lineItem: any) => lineItem.variant.id !== variantId)
        .map((lineItem: any) => ({
          ...(lineItem.customAttributes && {
            customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
          }),
          quantity: lineItem.quantity,
          variantId: lineItem.variant.id,
        }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, checkout, checkoutId]
  )

  const updateQuantity = useCallback(
    async (variantId, quantity, action = "add") => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map((lineItem: any) => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
        }),
        quantity: lineItem.variant.id === variantId ? quantity : lineItem.quantity,
        variantId: lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) await saveCheckout(data?.checkout)
      setLoading(false)

      trackCartUpdate(action, variantId, quantity, checkoutNormaliser(data?.checkout)?.lineItems)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, checkout, checkoutId, checkoutNormaliser]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map((lineItem: any) => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))
      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, checkout, checkoutId]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map((lineItem: any) =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }: { key: string; value: string }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }: { key: string; value: string }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) saveCheckout(data?.checkout)
      setLoading(false)
    },
    [lineItemsReplace, setErrors, saveCheckout, setLoading, checkout, checkoutId]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    checkout?.lineItems?.map(({ variant, quantity }: { variant: any; quantity: number }) =>
      trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems)
    )

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        checkoutId,
        lineItems: [],
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) saveCheckout(data?.checkout)

    setLoading(false)
  }, [lineItemsReplace, setErrors, saveCheckout, setLoading, trackCartUpdate, checkout, checkoutId])

  return {
    addToCart,
    removeFromCart,
    updateQuantity,
    updateVariant,
    updateItem,
    clearCart,
    loading,
    errors,
  }
}
