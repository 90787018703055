import React from "react"
import { VStack, HStack, Text } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import { useShopify } from "@app/hooks/useShopify"

const CartMiniSummary: React.FC = () => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  const amountDue = formatMoney(checkout?.paymentDueV2?.amount)

  return (
    <VStack width="full" align="stretch" bg={"grey.200"} py={4} mb={[4, 6]} m="0 !important">
      <HStack justify="center">
        <Text size="14">Total</Text>
        <Text size="14" fontWeight="600">
          {amountDue}
        </Text>
      </HStack>
    </VStack>
  )
}

export default React.memo(CartMiniSummary)
