import React, { useEffect, useMemo } from "react"
import { Box } from "@chakra-ui/react"
import CartUpsellProductCards from "../Sections/CartUpsellProductCards/CartUpsellProductCards"
import { useShopify } from "@app/hooks/useShopify"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCore } from "@app/hooks/useCore"
import { useLazyQuery } from "@apollo/client"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, A11y } from "swiper"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

SwiperCore.use([Navigation, Pagination, A11y])

type Props = {
  isExpanded: boolean
}

const CartUpsellProducts: React.FC<Props> = ({ isExpanded }) => {
  const {
    graphql: {
      queries: { GET_CART_RECOMMENDATIONS },
    },
  } = useCore()
  const { checkout } = useCheckoutContext()
  const { productNormaliser } = useShopify()

  const ids = useMemo(
    () =>
      checkout?.lineItems?.map(
        ({
          variant: {
            product: { id },
          },
        }: any) => id
      ),
    [checkout?.lineItems]
  )

  const [getRecommendations, { data: items, called, error }] = useLazyQuery(GET_CART_RECOMMENDATIONS(ids), {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      firstImages: 2,
      firstVariants: 12,
    },
  })

  if (error) console.error(error)

  // flatten product objects, remove duplicates, randomise and limit to 9 products
  const recommendations = useMemo(
    () =>
      items
        ? []
            .concat(...Object.values(items)?.map((products: any) => products?.map(productNormaliser)))
            .filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.id === v.id) === i)
            .sort(() => Math.random() - 0.5)
            .filter((item: any) => item.variants.some((variant: any) => Object.entries(variant?.compareAtPriceV2).length === 0))
            .slice(0, 9)
        : [],
    [items, productNormaliser]
  )

  useEffect(() => {
    if (!ids || called) return
    getRecommendations()
  }, [ids, called, getRecommendations])

  return recommendations?.length ? (
    <Box as="section" bg="brand.light">
      {isExpanded && (
        <Swiper init={isExpanded} navigation={true}>
          {recommendations?.map((item, index) => (
            <SwiperSlide key={index}>
              <CartUpsellProductCards key={item?.id?.toString()} index={index + 1} item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  ) : null
}

export default React.memo(CartUpsellProducts)
