import React from "react"
import { IconButton, Box } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { CartIcon } from "@app/theme/icons"

const CartWidget: React.FC = () => {
  const { count } = useCheckoutContext()
  const { state, dispatch } = useAppContext()

  const handleActiveCart = () => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }

  return (
    <Box position="relative" d="flex" justifyContent="center" alignItems="center">
      <IconButton
        variant="menu"
        title={`Open cart (${count})`}
        aria-label={`Open cart (${count})`}
        icon={<CartIcon width={["18px", "26px"]} height={["18px", "26px"]} />}
        onClick={handleActiveCart}
      />
      {count > 0 && (
        <Box
          position="absolute"
          top={["2px", "7px"]}
          right="-5px"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize={10}
        >
          {count}
        </Box>
      )}
    </Box>
  )
}

export default React.memo(CartWidget)
