import React from "react"
import sanityClient, { SanityClient } from "@sanity/client"
import { useConfigContext } from "@app/providers/config"

type ContextProps = {
  client: SanityClient
}

export const SanityContext = React.createContext<ContextProps | undefined>(undefined)

export const SanityProvider: React.FC = ({ children }) => {
  const {
    services: { sanity },
  } = useConfigContext()

  const client = sanityClient({
    projectId: sanity?.projectId,
    dataset: sanity?.dataset,
    token: sanity?.token,
    useCdn: true,
  })

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      client,
    }),
    [client]
  )

  return <SanityContext.Provider value={contextValue}>{children}</SanityContext.Provider>
}

export const useSanityContext = (): ContextProps => ({ ...React.useContext(SanityContext) } as ContextProps)
