import React from "react"
import { Box, HStack, SimpleGrid, Text, LinkBox, AspectRatio, Image, LinkOverlay } from "@chakra-ui/react"
import { SearchFormItem } from "./SearchFormItem"
import { useSearch, ResultList } from "@usereactify/search"
import { useStaticQuery, graphql } from "gatsby"
import Link from "@app/components/Link"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

export const SearchFormResults: React.ComponentProps<typeof ResultList>["renderResults"] = ({ products }) => {
  const { urlResolver } = useRoutes()
  const { searchQuery } = useSearch()
  const {
    settings: { routes },
  } = useConfigContext()

  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormResultPopularCollectionQuery>(graphql`
    query StaticSearchFormResultPopularCollection {
      search: sanityPageSearch {
        popularCollections: _rawPopularCollections(resolveReferences: { maxDepth: 10 })
      }
    }
  `)

  return products.length > 0 && searchQuery?.length > 0 ? (
    <Box mt={[2, 16]}>
      <HStack justify="space-between" mb={[2, 4]}>
        <Text size="12" textTransform="uppercase" letterSpacing="0.1em" fontWeight="800">
          Top Related Products
        </Text>
        <Text
          as={"a"}
          href={`${routes.SEARCH}?q=${searchQuery}`}
          size="12"
          textTransform="uppercase"
          letterSpacing="0.1em"
          fontWeight="800"
        >
          View All
        </Text>
      </HStack>
      <SimpleGrid columns={[2, 4]} gridGap={[3, 4]}>
        {products.map(product => (
          <SearchFormItem key={product.id} product={product} />
        ))}
      </SimpleGrid>
    </Box>
  ) : (
    <Box mt={[2, 16]}>
      <HStack justify="space-between" mb={[2, 4]}>
        <Text size="12" textTransform="uppercase" letterSpacing="0.1em" fontWeight="800">
          Top Related Collections
        </Text>
      </HStack>
      <SimpleGrid columns={[2, 4]} gridGap={[3, 4]}>
        {search?.popularCollections?.map(collection => (
          <LinkBox key={collection._id}>
            <AspectRatio ratio={1} w="100%" objectFit="cover" mb="16px">
              <Image
                src={collection?.image}
                alt={collection?.title}
                maxW="100%"
                fallbackSrc="https://plchldr.co/i/300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
              />
            </AspectRatio>
            <Box textAlign="left">
              <LinkOverlay as={Link} to={urlResolver(collection)?.url} title={collection?.title}>
                <Text as="h3" textStyle="paragraph" fontSize={"14px"} mb={2} lineHeight="21px">
                  {collection?.title}
                </Text>
              </LinkOverlay>
            </Box>
          </LinkBox>
        ))}
      </SimpleGrid>
    </Box>
  )
}
