import { useConfigContext } from "@app/providers/config"
import { useEffect, useState } from "react"
import { useShopify } from "@app/hooks/useShopify"
import { useCheckoutContext } from "@app/providers/checkout"

export const useKlaviyo = () => {
  const { formatMoney } = useShopify()
  const {
    app: { url },
    settings: { routes },
  } = useConfigContext()
  const { checkout } = useCheckoutContext()

  const [klaviyoLoaded, setKlaviyoLoaded] = useState(typeof window !== `undefined` ? !!window._learnq : false)

  const isDev = process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development"

  useEffect(() => {
    if (klaviyoLoaded) {
      return
    }

    // Poll until Klaviyo is loaded via GTM
    const interval = setInterval(() => {
      if (window._learnq) {
        setKlaviyoLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  const cleanData = (data: any) => {
    try {
      const clean = JSON.parse(
        JSON.stringify(data, (k, v) =>
          ["id", "internal", "parent", "metadata"].includes(k) || k.startsWith("_")
            ? undefined
            : ["image", "thumbnail"].includes(k)
            ? { alt: v?.alt, url: v?.asset?.url }
            : ["handle"].includes(k)
            ? v?.current
            : ["date", "dateCreated", "dateUpdated", "publishedAt"].includes(k)
            ? `${new Date(v).toISOString()}`
            : ["content", "subcontent", "additionalContent", "description"].includes(k) && Array.isArray(v)
            ? v?.map(({ children }) => children?.[0]?.text)?.join("<br/>")
            : Array.isArray(v) && !v?.length
            ? undefined
            : v?.constructor === Object && Object.entries(v)?.length === 0
            ? undefined
            : typeof v === "boolean"
            ? v
            : v || undefined
        )
      )
      return clean
    } catch (err) {
      console.error("Error parsing klaviyo event data")
    }
    return
  }

  const identify = customer => {
    if (window._learnq) {
      if (isDev) {
        console.log(`[KLAVIYO] identify fired.`)
      }
      window._learnq.push([
        "identify",
        {
          $email: `${customer?.email}`,
          $first_name: `${customer?.firstName || ""}`,
          $last_name: `${customer?.lastName || ""}`,
        },
      ])
    } else {
      if (isDev) {
        console.log(`[KLAVIYO] identify fired (_learnq missing).`)
      }
    }
  }

  const track = (type, item) => {
    if (window._learnq) {
      if (isDev) {
        console.log(`[KLAVIYO] track(${type}) fired.`)
      }
      window._learnq.push(["track", type, { ...cleanData(item) }])
    } else {
      if (isDev) {
        console.log(`[KLAVIYO] track(${type}) fired (_learnq missing).`)
      }
    }
  }

  const trackProduct = product => {
    const { title, id, collections, media, vendor, variants, handle } = product
    if (title) {
      const item = {
        Name: title,
        ProductID: id,
        Categories: collections?.map((collection: any) => collection.title),
        ImageURL: media?.[0]?.src,
        URL: `${url}${routes.PRODUCT}/${handle}`,
        Brand: vendor,
        Price: formatMoney(variants?.[0]?.priceV2?.amount, checkout?.currencyCode || "AUD"),
      }
      track("Viewed Product", item)
      track("trackViewedItem", {
        Title: item.Name,
        ItemId: item.ProductID,
        Categories: item.Categories,
        ImageUrl: item.ImageURL,
        Url: item.URL,
        Metadata: {
          Brand: item.Brand,
          Price: item.Price,
        },
      })
    }
  }

  return { klaviyoLoaded, identify, track, trackProduct }
}
