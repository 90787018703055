import { useCallback } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useKlaviyo } from "@app/hooks/useKlaviyo"
import { useCheckoutContext } from "@app/providers/checkout"
import { useTrackingContext } from "@app/providers/tracking"
import { useCustomerContext } from "@app/providers/customer"

export const useAnalytics = () => {
  const { tracked, gtm, latestVersion, colourOptionName, VisibilitySensor } = useTrackingContext()
  const {
    helpers: { decodeShopifyId, formatPrice, capitalise },
  } = useCore()
  // const { activeVariant } = useAppContext() // TODO: Look to switch to this instead of passing variants
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const { track } = useKlaviyo()
  const { productNormaliser } = useShopify()

  const decorateUrl = (url: string): string => {
    if (typeof window !== "undefined") {
      // @ts-ignore next-line
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function") {
        // @ts-ignore next-line
        const tracker = ga.getAll()[0]
        // @ts-ignore next-line
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const getVariantOptionValue = (options: any, selectedName: string) =>
    options?.find(({ name }: { name: string }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const trackPageView = useCallback(() => {
    setTimeout(() => {
      try {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              ...(customer?.id && { customer_id: customer?.id }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      } catch (e) {
        console.log(e)
      }
    }, 400)
  }, [gtm, tracked, customer, latestVersion])

  const trackStoreView = useCallback(
    store => {
      if (latestVersion) {
        const dataLayer = {
          event: "view_store",
          ecommerce: {
            store_ap21_number: store.ap21Number,
            store_name: store.title,
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion]
  )

  const trackFindInStoreView = useCallback(
    (product, variant) => {
      if (latestVersion) {
        const dataLayer = {
          event: "view_find_in_store",
          ecommerce: {
            currency: checkout?.currencyCode,
            item: {
              item_id: decodeShopifyId(product.id || "", "Product"),
              item_name: product.title,
              item_variant: variant ? variant.title : "",
            },
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion, checkout, decodeShopifyId, latestVersion]
  )

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item_list",
              ecommerce: {
                currency: checkout?.currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id || "", "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(product?.variants?.[0]?.selectedOptions, colourOptionName),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productImpression",
              ecommerce: {
                currencyCode: checkout?.currencyCode,
                impressions: [
                  {
                    id: id,
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: priceRange?.minVariantPrice?.amount,
                    list: list || "Collection", // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                    position: index,
                  },
                ],
              },
            }
        if (id) {
          gtm.dataLayer({ dataLayer })
        }
        console.log({ ...dataLayer })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId, formatPrice, latestVersion, colourOptionName]
  )

  const trackProductClick = useCallback(
    async (product, variant, index, list = null) => {
      const { collections, id, priceRange, title, vendor, productType } = product
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: checkout?.currencyCode,
                items: [
                  {
                    item_id: id?.length && id?.includes("Product") ? decodeShopifyId(id || "", "Product") : id,
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: "", // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currencyCode: checkout?.currencyCode,
                click: {
                  actionField: { list: list || "Collection" }, // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        if (id) {
          gtm.dataLayer({ dataLayer })
        }
        console.log({ ...dataLayer })
      }
    },
    [gtm, checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: checkout?.currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id || "", "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: formatPrice(variant?.priceV2?.amount || (product?.variants && product?.variants?.[0]?.priceV2?.amount)),
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currencyCode: checkout?.currencyCode,
                detail: {
                  actionField: { list: "Product Page" }, // Product Page
                  products: [
                    {
                      id: decodeShopifyId(id || "", "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        if (id) {
          gtm.dataLayer({ dataLayer })
        }
        console.log({ ...dataLayer })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId, formatPrice, latestVersion, colourOptionName]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(({ variant }: { variant: any }) => variant?.id === variantId)[0]

      if (selectedLineItem?.title) {
        const dataLayer = latestVersion
          ? {
              event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
              ecommerce: {
                currency: checkout?.currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(variantId || "", "ProductVariant"),
                    item_name: selectedLineItem?.title,
                    item_brand: selectedLineItem?.variant?.product?.vendor,
                    item_variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, colourOptionName),
                    item_category: selectedLineItem?.variant?.product?.productType,
                    price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
                    quantity,
                  },
                ],
              },
            }
          : {
              event: ["add", "change"].includes(type) ? "addToCart" : "removeFromCart",
              ecommerce: {
                currencyCode: checkout?.currencyCode,
                [type]: {
                  products: [
                    {
                      id: decodeShopifyId(variantId || "", "ProductVariant"),
                      name: selectedLineItem?.title,
                      brand: selectedLineItem?.variant?.product?.vendor,
                      category: selectedLineItem?.variant?.product?.productType,
                      price: selectedLineItem?.variant?.priceV2?.amount,
                      quantity,
                      variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, colourOptionName),
                      dimension2: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Size`),
                      dimension3: selectedLineItem?.variant?.availableForSale ? `In Stock` : `Out of Stock`,
                    },
                  ],
                },
              },
            }

        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })

        track(`Cart ${type === "add" ? "Add" : type == "change" ? "Update" : "Remove"}`, {
          ...selectedLineItem,
          quantity,
          product: selectedLineItem.product,
        })
      }
    },
    [gtm, checkout, decodeShopifyId, track, formatPrice, latestVersion, colourOptionName]
  )

  const trackCartView = useCallback(async () => {
    if (latestVersion && checkout?.lineItems?.length) {
      const dataLayer = {
        event: "view_cart",
        ecommerce: {
          currency: checkout?.currencyCode,
          items: checkout?.lineItems?.map((lineitem: any) => ({
            item_id: decodeShopifyId(lineitem?.variant?.id || "", "ProductVariant"),
            item_name: lineitem?.title,
            item_brand: lineitem?.variant?.product?.vendor,
            item_variant: getVariantOptionValue(lineitem?.variant?.selectedOptions, colourOptionName),
            item_category: lineitem?.variant?.product?.productType,
            price: formatPrice(lineitem?.variant?.priceV2?.amount),
            quantity: lineitem?.quantity,
          })),
        },
      }
      gtm.dataLayer({ dataLayer })
      console.log({ ...dataLayer })
    }
  }, [gtm, checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName])

  const trackWishlistUpdate = useCallback(
    async (type, product) => {
      if (latestVersion && product?.title) {
        const dataLayer = {
          event: ["add"].includes(type) ? "add_to_wishlist" : "remove_from_wishlist",
          ecommerce: {
            currency: checkout?.currencyCode,
            items: [
              {
                item_id: decodeShopifyId(product?.selectedVariant?.id || "", "ProductVariant"),
                item_name: product?.title,
                item_brand: product?.vendor,
                item_variant: getVariantOptionValue(product?.selectedVariant?.selectedOptions, colourOptionName),
                item_category: product?.productType,
                price: formatPrice(
                  product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                ),
              },
            ],
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion]
  )

  const trackLogin = useCallback(
    async method => {
      if (latestVersion && method) {
        const dataLayer = {
          event: "login",
          ecommerce: {
            method: capitalise(method),
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion, capitalise]
  )

  const trackSignup = useCallback(
    async method => {
      if (latestVersion && method) {
        const dataLayer = {
          event: "sign_up",
          ecommerce: {
            method: capitalise(method),
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion, capitalise]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (latestVersion && method) {
        const dataLayer = {
          event: "share",
          ecommerce: {
            method: capitalise(method),
            content_type: type,
            content_id: id,
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion, capitalise]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (latestVersion && type) {
        const dataLayer = {
          event: "select_content",
          ecommerce: {
            content_type: type,
            content_id: id,
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion]
  )

  const trackSearch = useCallback(
    async term => {
      if (latestVersion && term) {
        const dataLayer = {
          event: "search",
          ecommerce: {
            search_term: term,
          },
        }
        gtm.dataLayer({ dataLayer })
        console.log({ ...dataLayer })
      }
    },
    [gtm, latestVersion]
  )

  return {
    tracked,
    trackPageView,
    trackStoreView,
    trackFindInStoreView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
    VisibilitySensor,
  }
}
