import React from "react"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
  CloseButton,
  Heading,
  DrawerFooter,
} from "@chakra-ui/react"
import SizeGuideAccordions from "./SizeGuideAccordions"
import { useAppContext } from "@app/providers/app"
import { Link } from "gatsby"

const SizeGuideDrawer: React.FC = () => {
  const { state, dispatch, headerOffset } = useAppContext()

  const onToggle = () => {
    dispatch({
      type: "sizeguide",
      payload: !state.activeSizeguide,
    })
  }

  return (
    <Drawer
      onClose={onToggle}
      isOpen={state.activeSizeguide}
      motionPreset="slideInBottom"
      placement="left"
      size="lg"
      blockScrollOnMount={true}
      returnFocusOnClose={false}
    >
      <DrawerOverlay
        sx={{
          "&": {
            top: `${headerOffset}px !important`,
          },
        }}
      />
      <DrawerContent
        p={["0", "12"]}
        sx={{
          "&": {
            top: `${headerOffset}px !important`,
          },
        }}
      >
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Heading size="h3">Size Guide</Heading>
            <CloseButton onClick={onToggle} borderRadius="0" />
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <SizeGuideAccordions />
        </DrawerBody>
        <DrawerFooter justifyContent="center">
          <Text as={Link} to={"/mystylist"} size="12" letterSpacing="1px" textAlign="center" textTransform="uppercase">
            Need assistance? consult <b>mystylist</b>.
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(SizeGuideDrawer)
