import React from "react"
import { Box, Button, HStack } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"
import Link from "@app/components/Link"

type Props = {
  product: any
  variant: any
  loading: boolean
  multipleVariants: boolean
  productLink: any
}

const UpsellAddToCart: React.FC<Props> = ({ product, variant, loading, multipleVariants, productLink }) => {
  const { addToCart, loading: cartLoading } = useCart()

  const isGiftCard = product.productType === "Gift Card"

  const getText = () => {
    if (!variant) {
      return isGiftCard ? "Please select a value" : "Please select a size"
    }

    return variant?.availableForSale ? "Add to Cart" : "Sold out"
  }

  const handleAction = () => {
    addToCart(variant?.id)
  }

  return (
    <>
      <HStack spacing={4} mt={6} mb={4} align="stretch">
        <Box w="100%">
          {multipleVariants ? (
            <Button
              as={Link}
              to={productLink}
              variant="outline"
              size="sm"
              disabled={!variant?.availableForSale || loading}
              isLoading={cartLoading}
            >
              View Product
            </Button>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={handleAction}
              disabled={!variant?.availableForSale || loading}
              isLoading={cartLoading}
            >
              {getText()}
            </Button>
          )}
        </Box>
      </HStack>
    </>
  )
}

export default React.memo(UpsellAddToCart)
