import React from "react"
import { IconButton } from "@chakra-ui/react"
import { SearchIcon } from "@app/theme/icons"
import { useAppContext } from "@app/providers/app"

const SearchWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = () => {
    dispatch({
      type: "search",
      payload: !state.activeSearch,
    })
  }

  return (
    <IconButton
      aria-label="Open search"
      variant="menu"
      icon={<SearchIcon width={["18px", "26px"]} height={["18px", "26px"]} />}
      onClick={onToggle}
    />
  )
}

export default React.memo(SearchWidget)
