import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useShopify } from "@app/hooks/useShopify"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useAnalytics } from "@app/hooks/useAnalytics"
// import { useLocationContext } from "@app/providers/location"
// import { useCheckout } from "@app/hooks/useCheckout"

type ContextProps = {
  id: string
  url: string
  count: number
  checkout: any
  loading: boolean
  gotoCheckout: () => void
  saveCheckout: (checkout: any) => void
  createCheckout: (presentmentCurrencyCode: string | undefined, forceNew: boolean) => void
}

export const CheckoutContext = React.createContext<ContextProps | undefined>(undefined)

export const CheckoutProvider: React.FC = ({ children }) => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CHECKOUT_CREATE },
      queries: { GET_CHECKOUT },
    },
  } = useCore()
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const { shop } = useShop()
  const { decorateUrl } = useAnalytics()
  const { callFunction } = useFunctions()
  const { customer } = useCustomerContext()
  const { checkoutNormaliser } = useShopify()
  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkout, setCheckout] = useState<any>({})
  const [loading, setLoading] = useState(false)
  // const { location } = useLocationContext()
  // const { migrateCheckout } = useCheckout()

  const id = useMemo(() => checkout?.id || storage.get(keys.checkout), [storage, keys.checkout, checkout?.id])

  const url = useMemo(
    () => (checkout?.webUrl ? decorateUrl(checkout.webUrl.replace(`${store.shopName}.myshopify.com`, store.shopDomain)) : ""),
    [checkout, store, decorateUrl]
  )

  const count = useMemo(
    () =>
      checkout?.lineItems?.reduce(
        (count: number, lineItem: any, i: number) => (i ? count + parseInt(lineItem.quantity) : parseInt(lineItem.quantity)),
        0
      ) || 0,
    [checkout]
  )

  useEffect(() => {
    createCheckout(shop?.currencyCode)
    // if (shop && shop?.primaryDomain?.localization?.country !== location) migrateCheckout(shop?.currencyCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCheckout = useCallback(async () => {
    try {
      if (id) {
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ checkoutId: id })
        return checkout
      }
      return false
    } catch (e) {
      console.error(e.message)
    }
  }, [id, getCheckoutQuery])

  const saveCheckout = useCallback(
    checkout => {
      try {
        setCheckout(checkoutNormaliser(checkout))
        storage.set(keys.checkout, checkout?.id)
      } catch (e) {
        console.error(e.message)
      }
    },
    [setCheckout, checkoutNormaliser, keys, storage]
  )

  const createCheckout = useCallback(
    async (presentmentCurrencyCode = "AUD", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: { input: { presentmentCurrencyCode } },
          })
          if (checkout) saveCheckout(checkout)
        } else {
          saveCheckout(existingCheckout)
        }
      } catch (e) {
        storage.remove(keys.checkout)
        console.error(e.message)
      }
    },
    [getCheckout, saveCheckout, checkoutCreate, keys, storage]
  )

  const gotoCheckout = useCallback(async () => {
    setLoading(true)
    if (customer?.email && shop?.plan?.shopifyPlus) {
      try {
        const response = await callFunction("checkoutMultipass", {
          customerEmail: customer?.email,
          checkoutId: checkout.id,
          webUrl: checkout.webUrl,
        })
        const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkout.webUrl
        window.location.replace(url)
      } catch (e) {
        window.location.replace(checkout.webUrl)
      }
    } else {
      window.location.replace(checkout.webUrl)
    }
  }, [callFunction, checkout, customer, shop])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      loading,
      checkout,
      gotoCheckout,
      saveCheckout,
      createCheckout,
    }),
    [id, url, count, loading, checkout, gotoCheckout, saveCheckout, createCheckout]
  )

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>
}

export const useCheckoutContext = (): ContextProps => ({ ...React.useContext(CheckoutContext) } as ContextProps)
