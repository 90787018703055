export const Heading = {
  baseStyle: {
    fontWeight: [800],
    letterSpacing: ["0.2em"],
    textTransform: "uppercase",
  },
  sizes: {
    display: {
      fontSize: ["48", "72px"],
      fontWeight: [500],
      letterSpacing: ["0.05em"],
      fontFamily: ["display"],
      color: ["grey.900"],
      textTransform: "none",
    },
    h1: {
      fontSize: ["20px", "24px"],
    },
    h2: {
      fontSize: ["20px", "24px"],
    },
    h3: {
      fontSize: ["18px"],
    },
    h4: {
      fontSize: ["14px"],
      lineHeight: ["22px"],
    },
    h5: {
      fontSize: ["14px"],
      fontWeight: [600],
    },
    h6: {
      fontSize: ["10px"],
      letterSpacing: ["0.2em", "0.1em"],
    },
  },
}
