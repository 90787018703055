export const Form = {
  parts: ["requiredIndicator"],
  baseStyle: {
    requiredIndicator: {
      color: "#000000",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
