export const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      bg: "white",
      color: "grey.900",
    },
    a: {
      color: "grey.900",
    },
    ".rte": {
      h1: { mb: 4, fontSize: ["20px", "24px"], fontWeight: 800 },
      h2: { mb: 4, fontSize: ["20px", "24px"], fontWeight: 800 },
      h3: { mb: 4, fontSize: "18px", fontWeight: 600 },
      h4: { mb: 4, fontSize: "14px", fontWeight: 600 },
      h5: { mb: 4, fontSize: "14px", fontWeight: 500 },
      h6: { mb: 4, fontSize: "10px", fontWeight: 600 },
      ul: { mb: 4, paddingLeft: "20px" },
      ol: { mb: 4, paddingLeft: "20px" },
      li: { mb: 2 },
      blockquote: { mb: 4 },
      p: { mb: 4 },
      a: { textDecoration: "underline" },
      img: { display: "inline-block" },
    },
    ".firebase-emulator-warning": { d: "none" },
    ".swiper-container": {
      w: "full",
      h: "full",
    },
    ".swiper-slide": {
      bg: "white",
      d: "flex",
      h: "auto",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "lg",
      textAlign: "center",
    },
    ".swiper-slide img": {
      d: "block",
      w: "full",
      h: "full",
      objectFit: "cover",
    },
    ".swiper-pagination-bullets": {
      d: "flex",
      alignItems: "center",
      justifyContent: "center",
      pb: [4, 8],
      zIndex: 1,
    },
    ".swiper-pagination-bullets > .swiper-pagination-bullet": {
      d: "block",
      w: 2,
      h: 2,
      mx: 2,
      borderRadius: "full",
      cursor: "pointer",
      bg: "grey.300",
      _hover: {
        bg: "grey.700",
      },
    },
    ".swiper-pagination-bullets > .swiper-pagination-bullet.swiper-pagination-bullet-active": {
      bg: "grey.900",
      _hover: {
        bg: "grey.700",
      },
    },
    ".metrics": {
      fontSize: "10px",
    },
    ".product__form-wrapper": {
      position: "sticky",
      top: "150px",
      zIndex: "9",
      height: "min-content",
    },
  },
}
