export const textStyles = {
  paragraph: {
    fontSize: ["16px"],
  },
  paragraph2: {
    fontSize: ["14px"],
  },
  pricing: {
    fontSize: ["14px"],
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    fontWeight: 600,
  },
  breadcrumbs: {
    fontSize: ["12px"],
  },
  navigation1: {
    fontSize: ["12px"],
    textTransform: "uppercase",
    letterSpacing: ["0.2em"],
    fontWeight: 800,
    _hover: {
      textDecoration: "none",
    },
  },
  navigation2: {
    fontSize: ["10px"],
    textTransform: "uppercase",
    letterSpacing: ["0.2em"],
    fontWeight: 600,
  },
  navigation3: {
    fontSize: ["8px"],
    textTransform: "uppercase",
    letterSpacing: ["0.1em"],
    fontWeight: 600,
  },
  button1: {
    fontSize: ["14px"],
    textTransform: "uppercase",
    letterSpacing: ["0.2em"],
    fontWeight: 600,
  },
  button2: {
    fontSize: ["12px"],
    textTransform: "uppercase",
    letterSpacing: ["0.2em"],
    fontWeight: 600,
  },
  button3: {
    fontSize: ["11px"],
    textTransform: "uppercase",
    letterSpacing: ["0.1em"],
    fontWeight: 600,
  },
  filters: {
    color: "grey.700",
    fontSize: ["12px"],
  },
}
