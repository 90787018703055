import React from "react"
import { Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"

const Copyright: React.FC = () => {
  const { organisation } = useStaticQuery<GatsbyTypes.StaticLayoutQuery>(graphql`
    query StaticLayout {
      organisation: sanitySettingOrganisation {
        title
      }
    }
  `)

  return (
    <Text size="8" textStyle="navigation3" align={["center", "right"]} mt={[8, 0]}>
      &copy; {organisation?.title} {new Date().getFullYear()}. All rights reserved.
    </Text>
  )
}

export default React.memo(Copyright)
