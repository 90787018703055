import React from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Link as CustomLink, LinkProps } from "@chakra-ui/react"

interface GatsbyProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, '"ref"'> {
  external?: boolean
}

type Props = GatsbyProps & LinkProps

const Link: React.FC<Props> = ({ children, to = "", external = false, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)
  return internal ? (
    <CustomLink as={GatsbyLink} to={to} {...other}>
      {children}
    </CustomLink>
  ) : (
    <CustomLink href={to} {...(external && { isExternal: true, target: "_blank", rel: "noreferrer" })} {...other}>
      {children}
    </CustomLink>
  )
}

export default React.memo(Link)
