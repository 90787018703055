export const Tag = {
  parts: ["container", "closeButton"],
  baseStyle: {},
  variants: {
    discount: {
      container: {
        boxShadow: "inset 0 0 0px 1px rgb(226, 232, 240)",
        height: 30,
        px: 4,
      },
      closeButton: {
        width: "unset",
        height: "unset",
      },
    },
  },
  sizes: {},
  defaultProps: {},
}
