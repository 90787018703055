import React from "react"
import Link from "@app/components/Link"
import { HStack, Text } from "@chakra-ui/react"
import { useNavigation } from "@app/hooks/useNavigation"
import NavigationHeaderDesktopMega from "./NavigationHeaderDesktopMega"

const NavigationHeaderDesktop: React.FC = () => {
  const { header: items } = useNavigation()

  return items?.length && items.length > 0 ? (
    <HStack as="nav" spacing="12" display={["none", "flex"]}>
      {items?.map(({ type, title, url, items }) =>
        type === "sub" ? (
          <NavigationHeaderDesktopMega key={title} title={title} items={items} url={url} />
        ) : (
          <Text color="white.900" size="nav" as={props => <Link to={url} {...props} />} textStyle="navigation1" key={title} px={4} pb="4">
            {title}
          </Text>
        )
      )}
    </HStack>
  ) : null
}

export default React.memo(NavigationHeaderDesktop)
