export const Table = {
  baseStyle: {
    tr: {
      borderBottom: "none",
    },
    th: {
      pr: [2, 4],
      py: [2, 4],
      fontSize: ["12", "14"],
      fontWeight: "600",
      verticalAlign: "top",
    },
    td: {
      pr: [2, 4],
      py: [2, 4],
      fontSize: ["12", "14"],
      fontWeight: "500",
      borderColor: "grey.300",
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unstyled",
  },
}
