export const Text = {
  baseStyle: {
    color: "#000000",
    fontSize: ["15", "17"],
    fontFamily: "Mark Pro",
    fontWeight: 400,
    lineHeight: ["27.75px", "29.75px"],
  },
  variants: {
    gray: {
      color: "#717171",
    },
    label: {
      fontSize: 14,
      letterSpacing: "0.2em",
      fontWeight: 700,
      textTransform: "uppercase",
      fontFeatureSettings: "'pnum' on, 'lnum' on",
    },
  },
  sizes: {
    "40": {
      fontSize: ["28", "40"],
      lineHeight: 1.4,
    },
    "38": {
      fontSize: ["26", "38"],
      lineHeight: 1.2,
    },
    "20": {
      fontSize: ["18", "20"],
    },
    "17": {
      fontSize: "17",
      lineHeight: 1.8,
    },
    "2xl": {
      fontSize: "24",
      lineHeight: 1.35,
    },
    "16": {
      fontSize: "16",
      lineHeight: "24px",
    },
    "14": {
      fontSize: "14",
      lineHeight: "22px",
    },
    "13": {
      fontSize: "13",
      lineHeight: "18px",
    },
    "12": {
      fontSize: "12",
      lineHeight: "18px",
    },
    "10": {
      fontSize: "10",
      lineHeight: "16px",
    },
    "8": {
      fontSize: "8",
      lineHeight: "12px",
    },
    nav: {
      fontSize: "12",
      lineHeight: "17px",
      fontWeight: 700,
    },
    cow: {
      fontSize: ["20", "24"],
      lineHeight: 1.2,
    },
    base: {
      fontSize: "16",
      lineHeight: 1.85,
    },
  },
}
