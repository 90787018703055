import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Flex } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { FacebookIcon, InstagramIcon } from "@app/theme/icons"

const SocialMedia: React.FC = () => {
  const { social } = useStaticQuery<GatsbyTypes.StaticSocialMediaQuery>(graphql`
    query StaticSocialMedia {
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
    }
  `)

  const icons: Record<string, JSX.Element> = {
    Facebook: <FacebookIcon />,
    Instagram: <InstagramIcon />,
    default: <svg />,
  }

  return (
    <Flex justifyContent={["flex-start", "flex-end"]} mx={4} w="100%" pb="4">
      {social?.channels?.map(item => (
        <Link key={item?.title?.toString()} to={item?.url || ""} external mr={[4, 0]} ml={[0, 4]}>
          {icons[item?.title || "default"]}
        </Link>
      ))}
    </Flex>
  )
}

export default React.memo(SocialMedia)
