import React from "react"
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react"
import { BsDash, BsPlus } from "react-icons/bs"
import CartUpsellProducts from "./CartUpsellProducts"

const CartUpsells: React.FC = () => {
  return (
    <Box w="100%" px={[4, 6]} className="cart-upsells" pb="8px">
      <Accordion allowToggle>
        <AccordionItem borderBottom="none">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" textTransform="uppercase" fontSize="13px" letterSpacing="0.14em">
                    You May Also Like
                  </Box>
                  {isExpanded ? <BsDash fontSize="24px" /> : <BsPlus fontSize="24px" />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pt="0">
                <CartUpsellProducts isExpanded={isExpanded} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export default React.memo(CartUpsells)
