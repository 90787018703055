import React, { useRef } from "react"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Heading, Button } from "@chakra-ui/react"
import { useNewsletter } from "@app/hooks/useNewsletter"
import SubscribeForm from "./SubscribeForm"
import Brand from "../Brand"

type Props = {
  location: any
}

const Subscribe: React.FC<Props> = ({ location }: any) => {
  const { newsletter, activeSubscribe, handleClose } = useNewsletter(location)
  const initialRef = useRef()

  return (
    <Modal onClose={handleClose} isOpen={activeSubscribe} initialFocusRef={initialRef} motionPreset="slideInBottom" size="lg" isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center" px={[2, 16]} pt={[8, 16]} pb={2} borderRadius="none" h={["auto", "auto"]} w={["90vw", "auto"]}>
        <ModalHeader>
          <Heading as="h3" size="h5" fontSize="11px" fontWeight="400" mb={6}>
            {newsletter.additionalTitle}
          </Heading>
          <Brand width={["150px", "200px"]} mb={8} />
          <Heading as="p" size="h5" fontSize="11px" fontWeight="400" mb={6}>
            {newsletter.additionalContent}
          </Heading>
        </ModalHeader>
        <ModalBody mb={20}>
          <SubscribeForm initialRef={initialRef} location={location} newsletter />
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button fontSize="10px" fontWeight="600" variant="ghost" onClick={handleClose}>
            Continue Shopping
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}

export default React.memo(Subscribe)
