import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, VStack } from "@chakra-ui/react"
import { BsChevronDown } from "react-icons/bs"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"

import "../../theme/css/swiper.css"

import SwiperCore, { Pagination } from "swiper/core"

SwiperCore.use([Pagination])

import { Table } from "../Table"

const SizeGuideAccordions = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticSizeGuideAccordionsQuery>(graphql`
    query StaticSizeGuideAccordions {
      data: sanitySettingSizeGuide {
        tabs {
          _key
          title
          tables {
            title
            _key
            table {
              _key
              rows {
                cells
                _key
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Accordion allowToggle allowMultiple defaultIndex={0} mb={4}>
      {data?.tabs?.map(tab => (
        <AccordionItem key={tab?._key}>
          <AccordionButton d="flex" justifyContent="space-between" alignItems="center" py={4} pr={2} _hover={{}} _active={{}}>
            <Heading size="h4">{tab?.title}</Heading>
            <BsChevronDown />
          </AccordionButton>
          <AccordionPanel px="0">
            {tab?.tables?.length > 1 ? (
              <Box pos={"relative"} pb={12}>
                <Swiper pagination={{ clickable: true, el: `#${tab?.title}-pagination` }}>
                  {tab?.tables?.map(customTable => {
                    const { table } = customTable
                    return (
                      <SwiperSlide key={customTable?._key}>
                        <VStack w="100%" justifyContent="flex-start" alignItems="flex-start" py={2}>
                          <Heading size="h4">{customTable?.title}</Heading>
                          <Table w="100%" {...table} />
                        </VStack>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                <Box pos="absolute" bottom={0} insetX={0} id={`${tab?.title}-pagination`} />
              </Box>
            ) : (
              tab?.tables?.[0]?.table && (
                <Box>
                  <Table {...tab?.tables?.[0]?.table} />
                </Box>
              )
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default React.memo(SizeGuideAccordions)
