import firebase from "firebase/app"
import React, { useEffect, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"

type ContextProps = {
  firebase: any
  functions: firebase.functions.Functions | null
}

export const FirebaseContext = React.createContext<ContextProps | undefined>(undefined)

export const FirebaseProvider: React.FC = ({ children }) => {
  const {
    services: { firebase: firebaseConfig },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()

  const [functions, setFunctions] = useState<firebase.functions.Functions | null>(null)
  const development = process.env.NODE_ENV === "development"

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      firebase,
      functions,
    }),
    [functions]
  )

  useEffect(() => {
    if (!firebase.apps.length) firebase.initializeApp(firebaseConfig)

    import("firebase/functions")
      .then(() => {
        const functions = firebase.app().functions(firebaseConfig.region)
        // if (development) functions.useEmulator("localhost", 5001)
        setFunctions(functions)
      })
      .catch(error => console.error("Unable to lazy-load firebase/functions:", error))
  }, [firebaseConfig, development])

  return isBrowser ? <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider> : <>{children}</>
}

export const useFirebaseContext = (): ContextProps => ({ ...React.useContext(FirebaseContext) } as ContextProps)
