import * as React from "react"
import { AfterPayIcon, AmexIcon, MasterCardIcon, PayPalIcon, VisaIcon } from "@app/theme/icons"
import { Flex, Grid, GridItem } from "@chakra-ui/react"

export const PaymentIcons: React.FC<{ drawer?: boolean }> = ({ drawer = false }) => (
  <>
    {drawer ? (
      <Grid templateRows={"repeat(1, 1fr)"} templateColumns={drawer ? "repeat(5, 1fr)" : ["repeat(6, 1fr)", "repeat(5, 1fr)"]} gap={[4]}>
        <GridItem rowSpan={1} colSpan={drawer ? 1 : [2, 1]} d="flex" justifyContent="start" alignItems="center">
          <AmexIcon width={drawer ? "70%" : "100%"} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={drawer ? 1 : [2, 1]} d="flex" justifyContent="center" alignItems="center">
          <MasterCardIcon width={drawer ? "70%" : "100%"} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={drawer ? 1 : [2, 1]} d="flex" justifyContent="center" alignItems="center">
          <PayPalIcon width={drawer ? "70%" : "100%"} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={drawer ? 1 : [3, 1]} d="flex" justifyContent="center" alignItems="center">
          <VisaIcon width={drawer ? "70%" : ["140%", "50%"]} />
        </GridItem>
        <GridItem rowSpan={1} colSpan={drawer ? 1 : [3, 1]} d="flex" justifyContent="end" alignItems="center">
          <AfterPayIcon width={drawer ? "70%" : ["70%", "60%"]} />
        </GridItem>
      </Grid>
    ) : (
      <Flex alignItems="center" justifyContent="space-between" px={2}>
        <AmexIcon />
        <MasterCardIcon />
        <PayPalIcon />
        <VisaIcon />
        <AfterPayIcon />
      </Flex>
    )}
  </>
)
