import { useState, useEffect, useCallback } from "react"
import { useApolloClient } from "@apollo/client"
import { navigate } from "gatsby"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import { useWishlistContext } from "@app/providers/wishlist"
import { useKlaviyo } from "./useKlaviyo"

export const useWishlist = () => {
  const {
    helpers: { storage, encodeBase64, decodeBase64, getUrlParameter },
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const {
    app,
    settings: { keys, routes, params },
  } = useConfigContext()
  const client = useApolloClient()
  const { wishlist, setWishlist } = useWishlistContext()
  const { productNormaliser } = useShopify()
  const { addToCart, removeFromCart } = useCart()
  const [loading, setLoading] = useState(false)
  const [shareUrl, setShareUrl] = useState("")
  const [sharedWishlist, setSharedWishlist] = useState([])
  const savedWishlist = storage.get(keys.wishlist)
  const { track } = useKlaviyo()

  // const filterData = useCallback(
  //   (allowed, data) =>
  //     Object.keys(data)
  //       .filter(key => allowed.includes(key))
  //       .reduce((obj: any, key: string) => {
  //         obj[key] = data[key]
  //         return obj
  //       }, {}),
  //   []
  // )

  const formatData = useCallback(data => {
    // const allowed = ["id", "selectedSku", "selectedTitle", "handle", "title", "vendor", "image", "images", "tags"]
    return data //{ ...filterData(allowed, data) }
  }, [])

  const getWishlist = useCallback(
    async items => {
      const { data: wishlistLiveData } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(items?.map((product: any) => product?.handle)),
        variables: {
          firstCollections: 0,
          firstImages: 0,
          firstMedia: 10,
          firstMetafields: 0,
          firstVariants: 100,
        },
      })

      setWishlist(
        items?.map((item: any) => ({
          ...item,
          ...productNormaliser(wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]),
        }))
      )
    },
    [client, GET_PRODUCTS_BY_HANDLE, setWishlist, productNormaliser]
  )

  // initialize wishlist, only run once when first load
  useEffect(() => {
    if (savedWishlist?.length) {
      setWishlist(savedWishlist)
      getWishlist(savedWishlist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    storage.set(keys.wishlist, wishlist || [])
  }, [wishlist, keys.wishlist, storage])

  const getSharedWishlist = useCallback(async () => {
    const shared = getUrlParameter(params?.wishlist)

    const items = decodeBase64(shared)
      .split("|")
      .map((item: any) => ({
        handle: item.split(":")[0],
        selectedSku: item.split(":")[1],
      }))

    const { data: wishlistLiveData } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(items?.map((product: any) => product?.handle)),
      variables: {
        firstCollections: 0,
        firstImages: 10,
        firstMetafields: 0,
        firstVariants: 100,
      },
    })

    setSharedWishlist(
      items?.map((item: any) => ({
        ...item,
        ...productNormaliser(wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]),
      }))
    )
  }, [decodeBase64, client, GET_PRODUCTS_BY_HANDLE, setSharedWishlist, productNormaliser, getUrlParameter, params?.wishlist])

  const addToWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist((prevState: any) => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))

      setLoading(false)
      track("Wishlist Add", {
        title: data.title,
        images: data.images,
        priceRange: data.priceRange,
        tags: data.tags,
        variants: data.variants,
        vendor: data.vendor,
        handle: data.handle,
      })
    },
    [formatData, setWishlist, track, wishlist?.length]
  )

  const deleteFromWishlist = useCallback(
    async id => {
      setLoading(true)
      const product = wishlist.filter((item: any) => item?.id !== id)
      await setWishlist((prevState: any) => prevState.filter((item: any) => item?.id !== id))
      setLoading(false)
      track("Wishlist Remove", {
        title: product.title,
        images: product.images,
        priceRange: product.priceRange,
        tags: product.tags,
        variants: product.variants,
        vendor: product.vendor,
        handle: product.handle,
      })
    },
    [setWishlist, track, wishlist]
  )

  const moveToCart = useCallback(
    async (id, variantId) => {
      setLoading(true)
      await addToCart(variantId, 1)
      await setWishlist((prevState: any) => prevState.filter((item: any) => item?.id !== id))
      if (wishlist?.length > 1) await getWishlist(wishlist.filter((item: any) => item?.id !== id))
      setLoading(false)
    },
    [setLoading, setWishlist, getWishlist, addToCart, wishlist]
  )

  const moveToWishlist = useCallback(
    async (data, variantId) => {
      setLoading(true)
      const mappedData = formatData(data)
      await removeFromCart(variantId)
      await setWishlist((prevState: any) => (wishlist?.length ? [...prevState, mappedData] : [mappedData]))
      setLoading(false)
    },
    [setLoading, setWishlist, wishlist, removeFromCart, formatData]
  )

  const existsInWishlist = useCallback(id => wishlist?.filter((item: any) => item?.id === id).length, [wishlist])

  const updateWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist((prevState: any) => [...prevState.filter((item: any) => item?.id !== data?.id), mappedData])
      setLoading(false)
    },
    [setLoading, setWishlist, formatData]
  )

  const shareWishlist = useCallback(() => {
    setLoading(true)
    const string = wishlist?.map((item: any) => `${item.handle}:${item.selectedSku}`).join("|")
    const url = `${app?.url}${routes.SAVED}?id=${encodeBase64(string)}`
    setShareUrl(url)
    setLoading(false)
  }, [encodeBase64, setLoading, setShareUrl, wishlist, app?.url, routes.SAVED])

  const saveWishlist = useCallback(() => {
    setLoading(true)
    setWishlist(sharedWishlist)
    setSharedWishlist([])
    navigate(`${routes.SAVED}`, { replace: true })
    setLoading(false)
  }, [setLoading, setWishlist, setSharedWishlist, sharedWishlist, routes])

  const count = wishlist?.length || 0

  return {
    wishlist,
    savedWishlist,
    sharedWishlist,
    getWishlist,
    getSharedWishlist,
    addToWishlist,
    deleteFromWishlist,
    updateWishlist,
    shareWishlist,
    saveWishlist,
    existsInWishlist,
    moveToCart,
    moveToWishlist,
    shareUrl,
    loading,
    count,
  }
}
