import React, { useCallback } from "react"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "./useRoutes"
import Link from "@app/components/Link"

export const useContent = (props: any) => {
  const {
    helpers: { sanityContent: content, sanitySerializers },
  } = useCore()

  const { urlResolver } = useRoutes()

  const serializers = sanitySerializers({
    marks: {
      document: ({ children, mark }) => {
        const link = urlResolver(mark?.document?.document)

        return (
          <Link to={link?.url} title={link?.title} textDecoration="underline">
            {children}
          </Link>
        )
      },
      link: ({ children, mark }) => {
        const link = urlResolver(mark?.link)

        return (
          <Link external={link?.external} to={link?.url} title={link?.title} textDecoration="underline">
            {children}
          </Link>
        )
      },
    },
  })
  const sanityContent = useCallback(rawContent => content(rawContent, serializers), [content, serializers])
  return sanityContent(props?.content)
}
