import React from "react"
import { useCore } from "@app/hooks/useCore"
import { useMediaMobile } from "@app/hooks/useMedia"
import NavigationHeaderDesktop from "./NavigationHeaderDesktop"
import NavigationHeaderMobile from "./NavigationHeaderMobile"

const NavigationHeader: React.FC = () => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const isMobile = useMediaMobile()

  return (
    <ErrorBoundary>
      {isBrowser && isMobile && <NavigationHeaderMobile />}
      <NavigationHeaderDesktop />
    </ErrorBoundary>
  )
}

export default React.memo(NavigationHeader)
