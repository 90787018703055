import React from "react"
import { Container, Breadcrumb as Breadcrumbs, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { useBreadcrumb } from "@app/hooks/useBreadcrumb"

type Props = {
  data: any
  location: any
  exclude: Array<string>
}

const Breadcrumb: React.FC<Props> = ({ data, location, exclude }) => {
  const { buildBreadcrumbs } = useBreadcrumb(location)
  const breadcrumbs = buildBreadcrumbs({ data })

  return location?.pathname !== "/" && !exclude.some(item => location.pathname.includes(item)) ? (
    <Container maxW="container.xxl" px={[4, 16]}>
      <Breadcrumbs pt={4} pb={4} lineHeight={1}>
        {breadcrumbs.map(item => (
          <BreadcrumbItem key={item?.title?.toString()}>
            <BreadcrumbLink as={Link} to={item?.url} isCurrentPage fontSize="12">
              {item?.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumbs>
    </Container>
  ) : null
}
export default React.memo(Breadcrumb)
