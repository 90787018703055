import React from "react"
import { Box, IconButton } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useWishlist } from "@app/hooks/useWishlist"
import Link from "@app/components/Link"
import { HeartIcon } from "@app/theme/icons"
import { useMediaMobile } from "@app/hooks/useMedia"

const WishlistWidget = () => {
  const isMobile = useMediaMobile()
  const {
    settings: { routes },
  } = useConfigContext()
  const { count } = useWishlist()

  return !isMobile ? (
    <Box position="relative" d="flex" justifyContent="center" alignItems="center">
      <IconButton
        as={props => <Link to={routes.WISHLIST} {...props} />}
        variant="menu"
        aria-label={`Open wishlist (${count})`}
        icon={<HeartIcon height={"26px"} width={"26px"} />}
      />
      {count > 0 && (
        <Box
          position="absolute"
          top={["2px", "7px"]}
          right="-5px"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize={10}
        >
          {count}
        </Box>
      )}
    </Box>
  ) : null
}

export default React.memo(WishlistWidget)
