import { extendTheme } from "@chakra-ui/react"

import { styles } from "./styles"
import { breakpoints } from "./breakpoints"
import { colors } from "./colors"
import { fonts } from "./fonts"
import { sizes } from "./sizes"
import { textStyles } from "./textStyles"
import {
  Breadcrumb,
  Button,
  Divider,
  Heading,
  Input,
  Text,
  Link,
  Select,
  Textarea,
  FormLabel,
  Form,
  Checkbox,
  Accordion,
  Table,
  Tag,
} from "./components"

const overrides = {
  styles,
  breakpoints,
  colors,
  fonts,
  sizes,
  textStyles,
  components: {
    Breadcrumb,
    Button,
    Divider,
    Heading,
    Input,
    Link,
    Select,
    Text,
    Textarea,
    FormLabel,
    Form,
    Checkbox,
    Accordion,
    Table,
    Tag,
  },
}

export default extendTheme(overrides)
