export const Breadcrumb = {
  baseStyle: {
    item: {
      verticalAlign: "middle",
      fontSize: ["12px"],
    },
    link: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _activeLink: {
        cursor: "default",
        textDecoration: "none",
        fontWeight: 800,
      },
    },
    separator: {
      color: "grey.900",
    },
  },
}
