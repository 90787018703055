import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"
import { useAppContext } from "@app/providers/app"
import { usePromotions } from "@app/hooks/usePromotions"

export const useNavigation = () => {
  const { dispatch } = useAppContext()
  const { urlResolver } = useRoutes()
  const { menu } = usePromotions()
  const { edgeNormaliser } = useShopify()

  const toggleGlobalAction = (action: string, value: boolean | string) => {
    dispatch({
      type: action,
      payload: value,
    })
  }

  const globalActionLinks = ["#sizeguide"]

  const { main, all } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      main: sanitySettingMenus {
        account {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          handle: _rawHandle(resolveReferences: { maxDepth: 10 })
        }
        header {
          items: _rawItems(resolveReferences: { maxDepth: 12 })
          handle: _rawHandle(resolveReferences: { maxDepth: 10 })
        }
        headerMobile {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          handle: _rawHandle(resolveReferences: { maxDepth: 10 })
        }
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
          handle: _rawHandle(resolveReferences: { maxDepth: 10 })
        }
      }
      all: allSanityNavigation(filter: { handle: { current: { nin: ["account", "header", "footer"] } } }) {
        edges {
          node {
            items: _rawItems(resolveReferences: { maxDepth: 10 })
            handle: _rawHandle(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  `)

  const buildItems = useCallback(
    (items: Maybe<Items>): Maybe<MenuItems> =>
      items?.map(link => {
        const type = link?._type?.replace("navigation", "").toLowerCase() as MenuTypes
        const internal = ["link", "sub"].includes(type)
        const item = !internal ? link[type] : undefined
        const items = link?.items ? buildItems(link?.items) : undefined
        const resolvedUrl = urlResolver(item)
        const title = link?.title || resolvedUrl?.title
        const url = !internal ? resolvedUrl?.url : link?.link
        return {
          item,
          items,
          type,
          url,
          title,
          ...(type === "feature" && { ...link }),
        }
      }) || [],
    [urlResolver]
  )
  const header = useMemo(
    () => buildItems(menu === "default" ? main?.header?.items : edgeNormaliser(all)?.find(({ handle }) => handle?.current === menu)?.items),
    [menu, main, all, buildItems, edgeNormaliser]
  )

  const headerMobile = useMemo(
    () =>
      buildItems(
        menu === "default" ? main?.headerMobile?.items : edgeNormaliser(all)?.find(({ handle }) => handle?.current === menu)?.items
      ),
    [menu, main, all, buildItems, edgeNormaliser]
  )

  const footer = useMemo(() => buildItems(main?.footer?.items), [main, buildItems])

  const account = useMemo(() => buildItems(main?.account?.items), [main, buildItems])

  return { header, headerMobile, footer, account, globalActionLinks, toggleGlobalAction }
}

/**
 * https://github.com/cometkim/gatsby-plugin-typegen/issues/146
 * Custom navigation types due to this issue.
 */
type Maybe<T> = T | undefined

type MenuTypes = "collection" | "product" | "sub" | "feature"
// type Handle = string | null

type Items = Array<{
  title: string
  _key: string
  _type: "navigationCollection" | "navigationSub" | "navigationFeature"
  link?: string
  items: Maybe<MenuItems>
  item: Maybe<Record<string, unknown>>
}>
type MenuItems = Array<{
  type: MenuTypes
  url: Maybe<string>
  title: string
  items: Maybe<MenuItems>
  item: Maybe<Record<string, unknown>>
}>
