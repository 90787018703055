import { useTheme, useMediaQuery } from "@chakra-ui/react"
export const useMedia = () => {
  const { breakpoints } = useTheme()

  const isSmall = useMediaQuery(`(max-width: ${breakpoints.sm.replace("rem", "") * 16}px)`)?.[0]
  const isMedium = useMediaQuery(`(min-width: ${breakpoints.sm.replace("rem", "") * 16 + 1}px)`)?.[0]
  const isLarge = useMediaQuery(`(min-width: ${breakpoints.md.replace("rem", "") * 16 + 1}px)`)?.[0]
  const isXLarge = useMediaQuery(`(min-width: ${breakpoints.lg.replace("rem", "") * 16 + 1}px)`)?.[0]

  return { isSmall, isMedium, isLarge, isXLarge }
}

export const useMediaMobile = () => {
  const { breakpoints } = useTheme()
  const result = useMediaQuery(`(max-width: ${breakpoints.sm.replace("em", "") * 16}px)`)
  return result?.[0] ?? result
}

export const useMediaDesktop = () => {
  const { breakpoints } = useTheme()
  const result = useMediaQuery(`(max-width: ${breakpoints.sm.replace("em", "") * 16 + 1}px)`)
  return result?.[0] ?? result
}
