import React from "react"
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Flex,
  VStack,
  Box,
  Text,
} from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { PaymentIcons } from "../PaymentIcons"
import CartMiniSummary from "./CartMiniSummary"
import CartMiniList from "./CartMiniList"
import { useStaticQuery, graphql } from "gatsby"
import { useContent } from "@app/hooks/useContent"
import { useCartSettings } from "@app/hooks/useCartSettings"
import CartUpsells from "./CartUpsells"

const CartMini: React.FC = () => {
  const { gotoCheckout, count } = useCheckoutContext()
  const { state, dispatch } = useAppContext()
  const { data } = useStaticQuery<GatsbyTypes.CartMiniQuery>(graphql`
    query CartMini {
      data: sanityPageCart {
        message: _rawMessage(resolveReferences: { maxDepth: 10 })
      }
    }
  `)

  const { message } = data || {}

  const formattedMessage = useContent({ content: message })

  const onClose = () => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }

  const { enableUpsells } = useCartSettings()

  return (
    <Drawer isOpen={state.activeCart} placement="right" onClose={onClose} motionPreset="slideInRight" size="md">
      <DrawerOverlay />
      <DrawerContent transition="all 0.15s ease">
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex justify="center">
            <Heading size="h5">Cart</Heading>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <CartMiniList />
        </DrawerBody>
        <DrawerFooter px={0}>
          <VStack mx="auto" w={"100%"}>
            {count > 0 && enableUpsells === true && <CartUpsells />}
            <CartMiniSummary />
            <Box w="100%" px={["32px", "52px"]} pt="16px">
              <Button fontSize="14px" w="100%" size="sm" letterSpacing="none" mb={2} height="10" onClick={gotoCheckout}>
                Checkout Now
              </Button>
            </Box>
            <Button fontSize="14px" fontWeight="600" variant="ghost" size="sm" textTransform="unset" letterSpacing="none" onClick={onClose}>
              Continue Shopping
            </Button>
            <Box px={10}>
              <Text fontSize="12px" fontWeight="600" textAlign="center">
                {formattedMessage}
              </Text>
            </Box>

            <Box px={8} pt={4}>
              <PaymentIcons drawer />
            </Box>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
export default React.memo(CartMini)
