import React, { useState } from "react"

export type ContextProps = {
  wishlist: any
  setWishlist: React.Dispatch<React.SetStateAction<any>>
}

export const WishlistContext = React.createContext<ContextProps | undefined>(undefined)

export const WishlistProvider: React.FC = ({ children }) => {
  const [wishlist, setWishlist] = useState()

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      wishlist,
      setWishlist,
    }),
    [wishlist, setWishlist]
  )
  return <WishlistContext.Provider value={contextValue}>{children}</WishlistContext.Provider>
}

export const useWishlistContext = (): ContextProps => ({ ...React.useContext(WishlistContext) } as ContextProps)
