import React, { useState, useCallback, useEffect } from "react"
import { useQuery } from "@apollo/client"

import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useKlaviyo } from "@app/hooks/useKlaviyo"

type ContextProps = {
  customer: any
  getCustomer: () => void
  setCustomer: (customer: any) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: any) => void
}

export const CustomerContext = React.createContext<ContextProps | undefined>(undefined)

export const CustomerProvider: React.FC = ({ children }) => {
  const { identify, track } = useKlaviyo()
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    settings: { keys },
  } = useConfigContext()
  const [customer, setCustomer] = useState(false)

  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const getCustomer = useCallback(
    async (tokens = null) => {
      const customerTokens = tokens || storage.get(keys.customer)

      if (customerTokens?.accessToken) {
        try {
          const {
            data: { customer, customerUserErrors },
          } = await getCustomerQuery({
            customerAccessToken: customerTokens?.accessToken,
          })

          if (!customerUserErrors?.length) setCustomer(customer)
          if (customerUserErrors?.length) storage.remove(keys.customer)
        } catch (e) {
          console.error(e.message)
        }
      }
    },
    [getCustomerQuery, setCustomer, keys, storage]
  )

  const saveCustomer = useCallback(
    customerAccessToken => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        storage.set(keys.customer, { accessToken, expiresAt })
        getCustomer()
      } catch (e) {
        console.error(e.message)
      }
    },
    [getCustomer, keys, storage]
  )

  const clearCustomer = useCallback(() => {
    try {
      storage.remove(keys.customer)
      setCustomer(false)
    } catch (e) {
      console.error(e.message)
    }
  }, [setCustomer, keys, storage])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer]
  )

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (customer?.email) {
      identify(customer)
      track("Account Login", customer)
    }
  }, [customer, identify, track])

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...React.useContext(CustomerContext) } as ContextProps)
