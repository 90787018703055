import React from "react"
import { Container, Flex, Box, Center } from "@chakra-ui/react"
import { useAnnouncement } from "@app/hooks/useAnnouncement"
import Link from "@app/components/Link"

const Announcement: React.FC = () => {
  const { item, hide } = useAnnouncement()
  return item && !hide ? (
    <Box as="section" bg="brand.primary">
      <Container maxW={"11xl"}>
        <Flex as="section" justify="space-between" align="center" color="white" px={[0, 4]}>
          <Box d="flex"></Box>
          <Center d="flex" flex={1} flexGrow={1} py={2} px={4}>
            <Box
              as={
                item.link?.link
                  ? props => <Link {...props} to={item.link?.link} target={item.link?.external ? "_blank" : undefined} />
                  : Box
              }
            >
              <Box
                color="white"
                noOfLines={1}
                fontSize={"10px"}
                textAlign="center"
                textTransform="uppercase"
                fontWeight="600"
                letterSpacing="0.1em"
              >
                {item.title}
              </Box>
            </Box>
          </Center>
        </Flex>
      </Container>
    </Box>
  ) : null
}

export default React.memo(Announcement)
