import React from "react"
import { Box, Slide, Container } from "@chakra-ui/react"
import { Provider } from "@usereactify/search"

import config from "@root/config.default.js"

import { useAppContext } from "@app/providers/app"
import { HeaderProps } from "@app/components/Header/useHeader"

import { SearchForm } from "./SearchForm"

export const SearchFormOverlay: React.FC<Props> = () => {
  const { state, dispatch, headerRef, activeStore } = useAppContext()

  const onToggle = () => {
    dispatch({
      type: "search",
      payload: !state.activeSearch,
    })
  }
  return (
    <Slide
      direction="top"
      in={state.activeSearch}
      style={{
        top: headerRef?.current?.clientHeight,
        width: "100%",
        zIndex: 10,
        bottom: 0,
      }}
    >
      <Box
        position="absolute"
        inset="0"
        bg="grey.900"
        opacity={state.activeSearch ? 0.5 : 0}
        transition={state.activeSearch ? "0.3s opacity ease" : ""}
        transitionDelay={state.activeSearch ? "0.3s" : "0"}
        onClick={onToggle}
      />
      <Box
        position="absolute"
        borderTop="1px solid"
        borderColor="grey.300"
        bg="white"
        insetX="0"
        overflowY="auto"
        pt={[54, 16]}
        pb={[33, 16]}
      >
        <Container maxW="container.lg" px="6">
          <Provider
            shopifyPermanentDomain={`${activeStore.shopName}.myshopify.com`}
            index={config.services.reactify.index ?? ""}
            instantSearch
          >
            <SearchForm />
          </Provider>
        </Container>
      </Box>
    </Slide>
  )
}

type Props = HeaderProps
